<div class="cm-paragraph cm-form-paragraph" [ngClass]="{ error: showError }">
  <label *ngIf="label" class="cm-label" for="{{ type }}-{{ id }}">
    {{ label | translate }}
    <span *ngIf="suggestedValue" (click)="writeValue(suggestedValue)">
      (
      <span class="input__suggested-value">{{ suggestedValueLabel || suggestedValue }}</span>
      )
    </span>
    <span class="font-normal" *ngIf="infoLabel">{{ infoLabel | translate }}</span>
  </label>
  <div class="relative flex items-center">
    <ng-container
      *ngIf="label === 'shared.common.email-address' || label === 'shared.common.password'; else defaultInput"
    >
      <input
        *ngIf="label === 'shared.common.email-address'"
        #input
        type="{{ type }}"
        class="cm-input"
        [ngClass]="{
          'cm-input-small': size === 'small',
          'cm-form-error-input': showError
        }"
        id="{{ type }}-{{ id }}"
        name="username"
        [(ngModel)]="value"
        (focus)="isFocused = true"
        (focusout)="isFocused = false; onTouched()"
        (ngModelChange)="onInput()"
        [disabled]="disabled || false"
        [placeholder]="placeholder || '' | translate"
        [attr.autocapitalize]="autocapitalize || null"
      />
      <input
        *ngIf="label === 'shared.common.password'"
        #input
        type="{{ type }}"
        class="cm-input"
        [ngClass]="{
          'cm-input-small': size === 'small',
          'cm-form-error-input': showError
        }"
        id="{{ type }}-{{ id }}"
        name="password"
        [(ngModel)]="value"
        (focus)="isFocused = true"
        (focusout)="isFocused = false; onTouched()"
        (ngModelChange)="onInput()"
        [disabled]="disabled || false"
        [placeholder]="placeholder || '' | translate"
        [attr.autocapitalize]="autocapitalize || null"
      />
    </ng-container>
    <ng-template #defaultInput>
      <input
        #input
        type="{{ type }}"
        class="cm-input"
        [ngClass]="{
          'cm-input-small': size === 'small',
          'cm-form-error-input': showError
        }"
        id="{{ type }}-{{ id }}"
        name="{{ label }}"
        [(ngModel)]="value"
        (focus)="isFocused = true"
        (focusout)="isFocused = false; onTouched()"
        (ngModelChange)="onInput()"
        [disabled]="disabled || false"
        [placeholder]="placeholder || '' | translate"
        [attr.autocapitalize]="autocapitalize || null"
      />
    </ng-template>
    <div class="cm-input-addons-right ml-4 empty:hidden">
      <ng-content select="[app-input-addons-right]"></ng-content>
    </div>
  </div>
  <span *ngIf="showError" class="cm-form-error-text"> {{ errorMessage || '' | translate }}</span>
  <ng-content></ng-content>
</div>
