<app-base-modal [modalId]="modalId" [modalTitle]="'trading-platform.modal.balances.title' | translate">
  <ng-container modal-header>
    <app-search
      [searchPlaceholder]="'trading-platform.modal.balances.search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    ></app-search>
  </ng-container>
  <ng-container modal-body>
    <table class="mt-2 table-auto w-full font-sans">
      <thead>
        <tr>
          <th
            *ngFor="let header of headers"
            class="py-2 text-[14px] leading-[20px] text-cm-grey-400 font-normal text-right first:text-left"
          >
            {{ header | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let currency of filteredCurrencyBalances"
          [ngClass]="{
            'cursor-pointer': modalActions.onCurrencySelect
          }"
          (click)="modalActions.onCurrencySelect && modalActions.onCurrencySelect(currency)"
        >
          <td class="py-4 whitespace-nowrap">
            <div class="flex items-center">
              <img
                class="h-10 w-10 rounded-full"
                [src]="'/assets/crypto/color/' + currency.name.toLowerCase() + '.svg'"
                alt="Currency logo"
              />
              <div class="ml-4">
                <div class="text-[14px] lg:text-[16px] font-semibold">
                  {{ getCurrencyDisplayName(currency.name) }}
                </div>
                <div class="text-[14px] font-medium text-cm-grey-400">{{ currency.name }}</div>
              </div>
            </div>
          </td>
          <td class="px-2 py-4 whitespace-nowrap text-right">
            {{ currency.totalBalance | appCurrencyFloor: currency.name | async }} {{ currency.name }}
          </td>
          <td class="px-2 py-4 whitespace-nowrap text-right">
            {{ currency.inOpenOrders | appCurrencyFloor: currency.name | async }} {{ currency.name }}
          </td>
          <td class="py-4 whitespace-nowrap text-sm text-right text-[14px] sm:text-[16px] font-semibold">
            {{ currency.available | appCurrencyFloor: currency.name | async }} {{ currency.name }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</app-base-modal>
