/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewSupportMessageDto } from '../models/new-support-message-dto';
import { SupportMessageDto } from '../models/support-message-dto';
import { SupportMessageSettingsDto } from '../models/support-message-settings-dto';
import { UnreadMessageCountDto } from '../models/unread-message-count-dto';

@Injectable({
  providedIn: 'root',
})
export class UserMessagesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation markMessageAsReadUsingPut
   */
  static readonly MarkMessageAsReadUsingPutPath = '/frontend/user/messages/{id}';

  /**
   * Mark user message with given id as read.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markMessageAsReadUsingPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessageAsReadUsingPut$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessagesControllerService.MarkMessageAsReadUsingPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mark user message with given id as read.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markMessageAsReadUsingPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessageAsReadUsingPut(params: {
    id: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.markMessageAsReadUsingPut$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getUsersMessagesUsingGet
   */
  static readonly GetUsersMessagesUsingGetPath = '/frontend/user/messages';

  /**
   * Get authenticated user's messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersMessagesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersMessagesUsingGet$Response(params?: {
    limit?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SupportMessageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessagesControllerService.GetUsersMessagesUsingGetPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SupportMessageDto>>;
      })
    );
  }

  /**
   * Get authenticated user's messages
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersMessagesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersMessagesUsingGet(params?: {
    limit?: number;
  },
  context?: HttpContext

): Observable<Array<SupportMessageDto>> {

    return this.getUsersMessagesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SupportMessageDto>>) => r.body as Array<SupportMessageDto>)
    );
  }

  /**
   * Path part for operation createSupportMessageUsingPost
   */
  static readonly CreateSupportMessageUsingPostPath = '/frontend/user/messages';

  /**
   * Create new support message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSupportMessageUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSupportMessageUsingPost$Response(params: {
    body: NewSupportMessageDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupportMessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessagesControllerService.CreateSupportMessageUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportMessageDto>;
      })
    );
  }

  /**
   * Create new support message
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSupportMessageUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSupportMessageUsingPost(params: {
    body: NewSupportMessageDto
  },
  context?: HttpContext

): Observable<SupportMessageDto> {

    return this.createSupportMessageUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupportMessageDto>) => r.body as SupportMessageDto)
    );
  }

  /**
   * Path part for operation uploadMessageFileUsingPost
   */
  static readonly UploadMessageFileUsingPostPath = '/frontend/user/messages/{messageId}/attachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadMessageFileUsingPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadMessageFileUsingPost$Response(params: {
    messageId: number;
    'content-type'?: 'multipart/form-data';
    body?: {
'attachment': Blob;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupportMessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessagesControllerService.UploadMessageFileUsingPostPath, 'post');
    if (params) {
      rb.path('messageId', params.messageId, {});
      rb.header('content-type', params['content-type'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportMessageDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadMessageFileUsingPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadMessageFileUsingPost(params: {
    messageId: number;
    'content-type'?: 'multipart/form-data';
    body?: {
'attachment': Blob;
}
  },
  context?: HttpContext

): Observable<SupportMessageDto> {

    return this.uploadMessageFileUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupportMessageDto>) => r.body as SupportMessageDto)
    );
  }

  /**
   * Path part for operation getAttachmentForMessageUsingGet
   */
  static readonly GetAttachmentForMessageUsingGetPath = '/frontend/user/messages/{messageId}/attachment/{attachmentId}';

  /**
   * Get attachment for given message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachmentForMessageUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentForMessageUsingGet$Response(params: {
    messageId: number;
    attachmentId: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessagesControllerService.GetAttachmentForMessageUsingGetPath, 'get');
    if (params) {
      rb.path('messageId', params.messageId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get attachment for given message
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachmentForMessageUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentForMessageUsingGet(params: {
    messageId: number;
    attachmentId: number;
  },
  context?: HttpContext

): Observable<Array<string>> {

    return this.getAttachmentForMessageUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getUnreadMessagesCountUsingGet
   */
  static readonly GetUnreadMessagesCountUsingGetPath = '/frontend/user/messages/unread-count';

  /**
   * Get authenticated user's unread message count
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnreadMessagesCountUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnreadMessagesCountUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UnreadMessageCountDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessagesControllerService.GetUnreadMessagesCountUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UnreadMessageCountDto>;
      })
    );
  }

  /**
   * Get authenticated user's unread message count
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnreadMessagesCountUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnreadMessagesCountUsingGet(params?: {
  },
  context?: HttpContext

): Observable<UnreadMessageCountDto> {

    return this.getUnreadMessagesCountUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<UnreadMessageCountDto>) => r.body as UnreadMessageCountDto)
    );
  }

  /**
   * Path part for operation getUsersMessagesSettingsUsingGet
   */
  static readonly GetUsersMessagesSettingsUsingGetPath = '/frontend/user/messages/settings';

  /**
   * Get user's messages settings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersMessagesSettingsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersMessagesSettingsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SupportMessageSettingsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessagesControllerService.GetUsersMessagesSettingsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportMessageSettingsDto>;
      })
    );
  }

  /**
   * Get user's messages settings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersMessagesSettingsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersMessagesSettingsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<SupportMessageSettingsDto> {

    return this.getUsersMessagesSettingsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<SupportMessageSettingsDto>) => r.body as SupportMessageSettingsDto)
    );
  }

}
