import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownPopoverService } from '@app/shared/components/dropdown/dropdown-popover.service';

@Component({
  selector: 'button[dropdown-trigger]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown-trigger.component.html',
  host: {
    '[class.dropdown-trigger]': 'true',
    '(click)': 'togglePopover($event)',
  },
})
export class DropdownTriggerComponent {
  constructor(private popoverService: DropdownPopoverService) {}

  togglePopover(event: Event) {
    this.popoverService.toggle();
  }
}
