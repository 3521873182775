import { AccountValueHistoryDto } from '@app/generated/models/account-value-history-dto';
import { ApiError } from '@app/shared/api/error.api';
import { AccountUpdateReason } from '@app/authenticated/portfolio/ui/portfolio.enum';
import { AccountDto } from '@app/generated/models/account-dto';

export type AccountId = number;

export enum ApiStateStatus {
  pending = 'pending',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export interface ApiStateBase {
  error: ApiError | null;
  status: ApiStateStatus;
}

export type ApiStateSingle<T> = ApiStateBase & {
  data: T | null;
};

export type ApiStateSingleMeta<T> = ApiStateBase & {
  meta: T;
};

export type ApiStateCollection<T> = ApiStateBase & {
  data: T[] | null;
};

//region Accounts
export type AccountsState = ApiStateCollection<AccountDto>;

export const initialAccountsState: AccountsState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region Update Account
export type UpdateAccountState = ApiStateSingleMeta<{ requestId: string; reason: AccountUpdateReason }>[];

export const initialUpdateAccountState: UpdateAccountState = [];
//endregion

//region Account value history
export type AccountsValueHistoryState = ApiStateCollection<{ id: AccountId; history: AccountValueHistoryDto[] }>;

export const initialAccountHistoryValueState: AccountsValueHistoryState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region Account value history total
export type valueHistoryTotalState = ApiStateCollection<AccountValueHistoryDto>;

export const initialAccountHistoryValueTotalState: valueHistoryTotalState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

//region Favorites
export type FavoritesState = ApiStateCollection<string>;

export const initialFavoritesState: FavoritesState = {
  data: null,
  error: null,
  status: ApiStateStatus.pending,
};
//endregion

export interface PortfolioState {
  accountId: number | null;
  accounts: AccountsState;
  updateAccount: UpdateAccountState;
  accountsValueHistory: AccountsValueHistoryState;
  valueHistoryTotal: valueHistoryTotalState;
  favorites: FavoritesState;
}

export const initialPortfolioState: PortfolioState = {
  accountId: null,
  accounts: initialAccountsState,
  updateAccount: initialUpdateAccountState,
  accountsValueHistory: initialAccountHistoryValueState,
  valueHistoryTotal: initialAccountHistoryValueTotalState,
  favorites: initialFavoritesState,
};
