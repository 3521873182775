import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPairWithStatsDto } from '@app/generated/models';
import { CurrencyPairs } from '@app/shared/store/currency-pairs/currency-pairs-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageService } from '@app/shared/services/language.service';
import { SelectableCurrency, SelectedCurrencyService } from '@app/shared/services/selected-currency.service';

interface CurrencyData {
  imgSrc: string;
  name: string;
  code: string;
  price: number;
  priceFormat: string;
  priceCurrency: string;
  change: number;
}

@Component({
  selector: 'app-currencies-table',
  templateUrl: './currencies-table.component.html',
  styleUrls: ['./currencies-table.component.scss'],
})
export class CurrenciesTableComponent implements OnInit {
  @Input() isMinimal = false;

  currencyData$: Observable<CurrencyData[]> = EMPTY;
  selectedCurrency$: Observable<SelectableCurrency>;
  displayedColumns = ['icon', 'name', 'currency', 'price', 'change', 'buy'];
  lang = '';

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private currencyPairs: CurrencyPairs,
    private selectedCurrency: SelectedCurrencyService,
    private translateService: TranslateService,
  ) {
    this.selectedCurrency$ = selectedCurrency.selectedCurrency$;
    this.lang = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.currencyPairs.loadCurrencyPairsWithStats();
    this.setUpData();
  }

  setUpData() {
    this.currencyData$ = combineLatest([
      this.currencyPairs.currencyPairsWithStats$,
      this.selectedCurrency$,
      this.languageService.currentLanguage$,
    ]).pipe(
      map(([currencyPairs, currencyChoice]) => ({
        pairs: currencyPairs.filter(
          (currencyPair: CurrencyPairWithStatsDto) => currencyPair.secondCurrency === currencyChoice,
        ),
        currency: this.translate.instant('currencies.units.' + currencyChoice),
      })),
      map(({ pairs, currency }) => {
        if (!pairs.length) {
          return this.limit([...Array(7).keys()].map(() => null));
        }

        return this.limit(
          pairs.map((pair: CurrencyPairWithStatsDto) => {
            const currencyCode = pair.firstCurrency;
            return {
              imgSrc: `/assets/crypto/color/${currencyCode.toLowerCase()}.svg`,
              name: this.translate.instant('crypto.' + currencyCode),
              url: `https://coinmate.io/cz/${this.translate.instant('crypto.' + currencyCode).toLowerCase()}-kurz/`,
              code: currencyCode,
              price: pair.lastPrice,
              priceFormat: '.2-2',
              priceCurrency: currency,
              change: pair.changeIn24Hours,
            };
          }),
        );
      }),
    );
  }

  limit(array: any[]) {
    if (this.isMinimal) {
      return array.slice(0, 5);
    } else {
      return array;
    }
  }

  onChangeCurrency(event: Event): void {
    const nextCurrency = SelectedCurrencyService.toSelectableCurrency((event.target as HTMLSelectElement).value);
    if (!nextCurrency) return;
    this.selectedCurrency.setSelectedCurrency(nextCurrency);
  }

  isDataEmpty(data: CurrencyData[]): boolean {
    return data.filter(Boolean).length === 0;
  }
}
