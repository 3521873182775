import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { LanguageService } from '@app-shared/services/language.service';
import { Currencies } from '@app-shared/store/currencies/currencies-facade.service';
import { CurrencyPairs } from '@app-shared/store/currency-pairs/currency-pairs-facade.service';
import { GuiParams } from '@app-shared/store/gui-params/gui-params-facade.service';
import { UserInfo } from '@app-shared/store/user-info/user-info-facade.service';
import { SseListenerService } from '@app/shared/services/sse-listener.service';
import { filter, first, take, takeUntil } from 'rxjs/operators';
import Hotjar from '@hotjar/browser';
import { init, browserTracingIntegration, BrowserOptions } from '@sentry/angular-ivy';
import pkg from 'package.json';
import { environment } from '@app/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InitService implements OnDestroy {
  private readonly isBrowser: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private languageService: LanguageService,
    private currencies: Currencies,
    private currencyPairs: CurrencyPairs,
    private guiParams: GuiParams,
    private userInfo: UserInfo,
    private sseListenerService: SseListenerService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  init() {
    this.guiParams.load();
    this.languageService.init();
    this.currencies.load();
    this.currencyPairs.loadCurrencyPairs();
    this.sseListenerService.init();
    this.currencyPairs.loadCurrencyPairsWithStatsWs();

    this.guiParams.loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.userInfo.load();
      }
    });

    if (this.isBrowser) {
      this.initHotjar();
      this.initSentry();
    }
  }

  initHotjar() {
    this.guiParams.guiParams$
      .pipe(
        filter((guiParams) => guiParams?.environment !== ''),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (guiParams) => {
          if ((guiParams.environment === 'PROD' || guiParams.environment === 'UAT') && guiParams.hotjarSiteId) {
            Hotjar.init(guiParams.hotjarSiteId, 6);
          }
        },
        error: () => {
          // TODO DTP-5035
        },
      });
  }

  initSentry() {
    const sentryOptions: BrowserOptions = {
      dsn: 'https://0b58c45cf6db4fa7a21df3173ac17385@o1157895.ingest.us.sentry.io/6240596',
      integrations: [browserTracingIntegration()],
      tracePropagationTargets: [
        /^(http|https|ws|wss):\/\/localhost/,
        /^(https|wss):\/\/coinmate.io/,
        /^(https|wss):\/\/stage.coinmate.io/,
        /^(https|wss):\/\/test.coinmate.cz/,
        /^(https|wss):\/\/exp.coinmate.cz/,
        /^\//,
      ],
      tracesSampleRate: 0.0,
    };

    this.guiParams.guiParams$
      .pipe(
        first((params) => params.environment !== ''),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (params) => {
          const env = params.environment;

          if (!this.isErrorReportingEnabled(env, params.version)) {
            return;
          }

          sentryOptions.environment = env.toLowerCase();

          if (env === 'PROD') {
            sentryOptions.sampleRate = 0;
            sentryOptions.tracesSampleRate = 0; // turn off tracing for now - btw. does not seem to do anything
          }

          const version = pkg.version;
          if (version) {
            sentryOptions.release = `coinmate@${version}`;
          }

          init({
            ...sentryOptions,

            // ignore browser extension errors
            denyUrls: [/extensions\//i, /extension:\/\//i, /^chrome:\/\//i],

            // ignore reCaptcha error - https://github.com/getsentry/sentry-javascript/issues/2514
            beforeSend: (event, hint) => {
              if (hint.originalException === 'Timeout') return null;
              return event;
            },
          });
        },
        error: () => {},
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private isErrorReportingEnabled(envName: string, version: string): boolean {
    if (environment.forceErrorReporting) return true;
    if (envName === 'PROD') return true;
    if (envName === 'STAGE') return true;
    if (envName === 'TEST') return false;
    // this excludes master, since its envName is 'UAT' and its DB ID is 'MASTER'
    return version.split('|')[2].trim() !== 'MASTER';
  }
}
