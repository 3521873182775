import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { startsWith } from 'ramda';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { take, takeUntil } from 'rxjs/operators';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { Subject } from 'rxjs';
import { GuiParamsDto } from '@app/generated/models/gui-params-dto';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

interface GtmEvent {
  [key: string]: string;
}

@Injectable()
export class SseListenerService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private readonly isBrowser: boolean;
  private eventSource!: EventSource;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 50;
  private reconnectInterval = 5000;
  private latestGuiParamsDto: GuiParamsDto | null = null;
  private reconnectTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor(
    private gtmService: GoogleTagManagerService,
    private guiParamsService: GuiParams,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  init() {
    if (!this.isBrowser) {
      return;
    }

    this.guiParamsService.guiParams$.pipe(takeUntil(this.unsubscribe$)).subscribe((guiParamsDto: GuiParamsDto) => {
      this.latestGuiParamsDto = guiParamsDto;
      this.addEventsFromGuiParamsResponse(guiParamsDto);
      this.initEventSource(guiParamsDto);
    });
  }

  private initEventSource(guiParamsDto: GuiParamsDto) {
    const proto = window.location.protocol;
    const host = window.location.host;

    if (this.eventSource) {
      this.eventSource.close();
    }

    this.eventSource = new EventSource(proto + '//' + host + '/frontend/sse');
    this.setupEventListeners(guiParamsDto);
  }

  private setupEventListeners(guiParamsDto: GuiParamsDto) {
    this.eventSource.onmessage = (e) => this.handleMessage(e, guiParamsDto);
    this.eventSource.onerror = () => this.handleError();
    this.eventSource.onopen = () => this.handleOpen();

    window.onbeforeunload = () => {
      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
        this.reconnectTimeout = null;
      }
      this.eventSource.close();
    };
  }

  private handleMessage(e: MessageEvent, guiParamsDto: GuiParamsDto) {
    try {
      const data = JSON.parse(e.data);

      if (typeof data['@type'] !== 'undefined' && data['@type'] === 'EHLO') {
        this.setChannelId(data.channelId);
        if (data.appVersion) {
          this.checkVersion(data.appVersion, guiParamsDto.version);
        }
        return;
      }

      if (typeof data.srcId !== 'undefined' && data.srcId !== sessionStorage.getItem('sseChannelId')) {
        return;
      }

      if (typeof data.payload === 'undefined') {
        return;
      }

      if (typeof data.payload['@type'] !== undefined && startsWith('Gtm', data.payload['@type'])) {
        const gtmEvent: GtmEvent = this.mapToGtmEvent(data.payload);
        if (gtmEvent.event) {
          this.gtmService.pushTag(gtmEvent);
        }
      }
    } catch (error) {}
  }

  private handleError() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectTimeout = setTimeout(() => {
        this.reconnectAttempts++;
        if (this.latestGuiParamsDto) {
          this.initEventSource(this.latestGuiParamsDto);
        }
      }, this.reconnectInterval);
    }
  }

  private handleOpen() {
    this.reconnectAttempts = 0;
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
  }

  private addEventsFromGuiParamsResponse(guiParamsDto: GuiParamsDto) {
    // quick n dirty sending events from gui-parameters
    guiParamsDto.gtm
      .map((e) => this.mapToGtmEvent(e))
      .filter((e) => e.event)
      .forEach((e: GtmEvent) => this.gtmService.pushTag(e));
  }

  private mapToGtmEvent(data: any): GtmEvent {
    return Object.keys(data)
      .filter((key: string) => !key.startsWith('@'))
      .reduce((obj: GtmEvent, key: string) => {
        return { ...obj, [key]: data[key] };
      }, {});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.eventSource) {
      this.eventSource.close();
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
  }

  private setChannelId(channelId: string) {
    if (this.isBrowser) {
      sessionStorage.setItem('sseChannelId', channelId);
    }
  }

  private checkVersion(sseVersion: string, currentVersion: string) {
    if (!this.isBrowser) {
      return;
    }

    if (sseVersion !== currentVersion) {
      const message = this.translateService.instant('error.version-mismatch');
      const reloadButton = `<button class="toastr-reload-button">${this.translateService.instant(
        'shared.common.reload-page',
      )}</button>`;

      const toastrMessage = `${message} <span class="toastr-reload-button">${reloadButton}</span>`;
      this.toastrService
        .info(toastrMessage, '', {
          enableHtml: true,
          closeButton: false,
          disableTimeOut: true,
        })
        .onTap.pipe(take(1))
        .subscribe(() => {
          window.location.reload();
        });
    }
  }
}
