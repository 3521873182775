<div class="textarea">
  <label class="textarea__label cm-label" [for]="id" *ngIf="label" translate>{{ label }}</label>
  <textarea
    class="textarea__input form-control cm-input"
    [name]="label"
    [id]="id"
    cols="30"
    rows="2"
    [ngClass]="{ 'textarea__input--error': errorMessage && !isValid }"
    [(ngModel)]="value"
    [disabled]="disabled || false"
    [placeholder]="placeholder ? (placeholder | translate) : ''"
    (focus)="isFocused = true"
    (focusout)="isFocused = false; onTouched()"
  >
  </textarea>
  <div class="textarea__error-message" *ngIf="showError" translate>
    {{ errorMessage || '' }}
  </div>
</div>
