<footer class="cm-footer">
  <div
    [ngClass]="{
      'cm-container': true,
      verification: appState === 'verification'
    }"
  >
    <a [routerLink]="['/' | localize]" class="!block md:!hidden">
      <img src="/assets/cm-logo.svg" height="24" alt="coinmate" class="cm-footer-logo" />
    </a>
    <div class="order-5 md:order-1 col cm-col">
      <div class="flex-grow">
        <a routerLink="/" class="w-max">
          <img src="/assets/cm-logo.svg" height="24" alt="coinmate" class="cm-footer-logo !hidden lg:!block" />
        </a>
      </div>
      <p *ngIf="(currentVersion$ | async) !== undefined" class="!text-xs">{{ currentVersion$ | async }}</p>
      <p class="cm-paragraph">&copy; Coinmate a. s. 2014–{{ getCurrentYear() }}</p>
      <button onclick="Cookiebot.renew()" class="btn-link">
        {{ 'shared.footer.cookies-settings' | translate }}
      </button>
    </div>
    <app-footer-link
      *ngIf="appState !== 'verification'"
      class="order-1 md:order-2 col"
      [columnData]="product"
    ></app-footer-link>
    <app-footer-link
      *ngIf="appState !== 'verification'"
      class="order-2 md:order-3 col"
      [columnData]="coinmate"
    ></app-footer-link>
    <app-footer-link
      *ngIf="appState !== 'verification'"
      class="order-3 md:order-4 col"
      [columnData]="resources"
    ></app-footer-link>
    <div class="order-4 md:order-5 col">
      <app-language-switch class="cm-footer-link" [type]="languageSwitchTypeEnum.footer"></app-language-switch>
    </div>
  </div>
</footer>
