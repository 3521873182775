<button
  class="flex items-center tooltip tooltip--bottom"
  [attr.data-tooltip]="
    (copied ? 'shared.common.copy-to-clipboard-done' : 'shared.common.copy-to-clipboard') | translate
  "
>
  <svg-icon
    class="copyIcon"
    [src]="copied ? '/assets/icons/icon-copied.svg' : '/assets/icons/icon-copy.svg'"
    title="{{ (copied ? 'shared.common.copy-to-clipboard-done' : 'shared.common.copy-to-clipboard') | translate }}"
    (click)="copyText()"
  ></svg-icon>
</button>
