<div class="flex justify-center items-center mb-5">
  <input type="text" style="display: none" [(ngModel)]="otp" appWebOtp />
  <code-input
    [isCodeHidden]="false"
    [codeLength]="6"
    [isCharsCode]="false"
    [initialFocusField]="0"
    [(ngModel)]="otp"
    (codeCompleted)="handleCodeCompleted($event)"
  >
  </code-input>
</div>
