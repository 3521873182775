import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { v4 as uuidv4 } from 'uuid';

import { ModalService } from '@app/shared/components/modal/modal.service';
import { ApiError } from '@app/shared/api/error.api';
import { AccountBalanceMoveDto } from '@app/generated/models/account-balance-move-dto';

import { ApiStateStatus, PortfolioState } from '@app/authenticated/portfolio/store/portfolio.state';
import { AccountUpdateReason, ErrorType, Modal } from '@app/authenticated/portfolio/ui/portfolio.enum';
import * as PortfolioActions from '@app/authenticated/portfolio/store/portfolio.actions';
import { selectUpdateAccountError } from '@app/authenticated/portfolio/store/portfolio.selectors';
import { Router } from '@angular/router';
import { UserAccountControllerService } from '@app/generated/services/user-account-controller.service';
import { PortfolioUserSettings } from '@app/authenticated/portfolio/ui/portfolio.type';

const PORTFOLIO_SETTINGS_LOCAL_STORAGE_KEY = 'portfolio.settings';

@Injectable()
export class PortfolioService implements OnDestroy {
  protected readonly unsubscribe$ = new Subject<void>();

  protected readonly apiStateStatusEnum = ApiStateStatus;

  private createAccountError$ = this.store.select(selectUpdateAccountError(AccountUpdateReason.createAccount));
  private renameAccountError$ = this.store.select(selectUpdateAccountError(AccountUpdateReason.renameAccount));
  private moveAccountBalanceError$ = this.store.select(
    selectUpdateAccountError(AccountUpdateReason.moveBalanceToAccount),
  );
  private deactivateAccountError$ = this.store.select(selectUpdateAccountError(AccountUpdateReason.deactivateAccount));

  onAccountUpdate$ = this.actions$.pipe(
    ofType(PortfolioActions.updateAccountSuccess),
    takeUntil(this.unsubscribe$),
    filter(({ meta }) =>
      [
        AccountUpdateReason.createAccount,
        AccountUpdateReason.renameAccount,
        AccountUpdateReason.deactivateAccount,
        AccountUpdateReason.updateBalance,
      ].includes(meta.reason),
    ),
  );

  createAccount(accountName: string) {
    const requestId = uuidv4();
    this.store.dispatch(
      PortfolioActions.createAccount({
        accountName,
        meta: {
          requestId,
        },
      }),
    );

    return requestId;
  }

  renameAccount(accountId: number, accountName: string) {
    const requestId = uuidv4();
    this.store.dispatch(
      PortfolioActions.renameAccount({
        accountId,
        accountName,
        meta: {
          requestId,
        },
      }),
    );

    return requestId;
  }

  deactivateAccount(accountId: number) {
    const requestId = uuidv4();
    this.store.dispatch(
      PortfolioActions.deactivateAccount({
        accountId,
        meta: {
          requestId,
        },
      }),
    );

    return requestId;
  }

  moveBalanceToAccount(accountId: number, balance: AccountBalanceMoveDto) {
    const requestId = uuidv4();
    this.store.dispatch(
      PortfolioActions.moveBalanceToAccount({
        accountId,
        balance,
        meta: {
          requestId,
        },
      }),
    );

    return requestId;
  }

  moveAllBalanceSToAccount(accountId: number, balance: AccountBalanceMoveDto) {
    const requestId = uuidv4();
    this.store.dispatch(
      PortfolioActions.moveBalanceToAccount({
        accountId,
        balance,
        meta: {
          requestId,
        },
      }),
    );

    return requestId;
  }

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly toastrService: ToastrService,
    private readonly modalService: ModalService,
    private readonly accountService: UserAccountControllerService,
    private readonly store: Store<PortfolioState>,
    private readonly actions$: Actions,
  ) {
    //region Create Account
    this.actions$
      .pipe(
        ofType(PortfolioActions.updateAccountSuccess),
        takeUntil(this.unsubscribe$),
        filter(({ meta }) => meta.reason === AccountUpdateReason.createAccount),
      )
      .subscribe((data) => {
        this.handleCreateAccountSuccess();
      });

    this.createAccountError$.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => {
      this.handlerCreateAccountError(error);
    });
    //endregion

    //region Rename Account
    this.actions$
      .pipe(
        ofType(PortfolioActions.updateAccountSuccess),
        takeUntil(this.unsubscribe$),
        filter(({ meta }) => meta.reason === AccountUpdateReason.renameAccount),
      )
      .subscribe((data) => {
        this.handleRenameAccountSuccess();
      });

    this.renameAccountError$.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => {
      this.handlerRenameAccountError(error);
    });
    //endregion

    //region Deactivate Account
    this.actions$
      .pipe(
        ofType(PortfolioActions.updateAccountSuccess),
        takeUntil(this.unsubscribe$),
        filter(({ meta }) => meta.reason === AccountUpdateReason.deactivateAccount),
      )
      .subscribe((data) => {
        this.handleDeactivateAccountSuccess();
      });

    this.deactivateAccountError$.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => {
      this.handlerDeactivateAccountError(error);
    });
    //endregion

    //region Move Account Balance
    this.actions$
      .pipe(
        ofType(PortfolioActions.updateAccountSuccess),
        takeUntil(this.unsubscribe$),
        filter(({ meta }) => meta.reason === AccountUpdateReason.moveBalanceToAccount),
      )
      .subscribe((data) => {
        this.handleMoveAccountBalanceSuccess();
      });

    this.moveAccountBalanceError$.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => {
      this.handlerMoveAccountBalanceError(error);
    });
    //endregion
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //region Create Account
  private handleCreateAccountSuccess() {
    this.toastrService.success(this.translateService.instant('shared.account.account-created'));
  }

  private handlerCreateAccountError(error: ApiError | null) {
    if (!error) {
      return;
    }

    if (error.code === 400) {
      switch (error.errorType) {
        case ErrorType.accountDuplicateNameException: {
          this.toastrService.error(
            this.translateService.instant('error.account-name-used'),
            this.translateService.instant('error.duplicate-name'),
          );
          break;
        }
        case ErrorType.maxNumberOfAccountsException: {
          this.toastrService.error(
            this.translateService.instant('error.only-n-accounts-allowed', {
              value: error.additionalInfo.limit,
            }),
            this.translateService.instant('error.maximum-accounts-reached'),
          );
          break;
        }
        default:
          this.toastrService.error(error.message, this.translateService.instant('error.request-invalid'));
      }

      return; // handled 400
    }

    this.toastrService.error(this.translateService.instant('error.temporary-server'));
  }
  //endregion

  //region Rename Account
  private handleRenameAccountSuccess() {
    this.toastrService.success(this.translateService.instant('shared.account.account-renamed'));
    this.modalService.closeDynamicModal(Modal.createAccount);
  }

  private handlerRenameAccountError(error: ApiError | null) {
    if (!error) {
      return;
    }

    if (error.code === 400) {
      switch (error.errorType) {
        case ErrorType.accountDuplicateNameException: {
          this.toastrService.error(
            this.translateService.instant('error.account-name-used'),
            this.translateService.instant('error.duplicate-name'),
          );
          break;
        }
        case ErrorType.invalidArgumentException: {
          this.toastrService.error(this.translateService.instant('error.invalid-argument'));
          break;
        }
        default:
          this.toastrService.error(error.message, this.translateService.instant('error.request-invalid'));
      }

      return; // handled 400
    }

    this.toastrService.error(this.translateService.instant('error.temporary-server'));
  }
  //endregion

  //region Deactivate Account
  private handleDeactivateAccountSuccess() {
    this.toastrService.success(this.translateService.instant('shared.account.account-deactivated'));
    this.modalService.closeDynamicModal(Modal.deactivateAccount);
    this.router.navigate(['/portfolio']);
  }

  private handlerDeactivateAccountError(error: ApiError | null) {
    if (!error) {
      return;
    }

    this.toastrService.error(this.translateService.instant('error.temporary-server'));
  }
  //endregion

  //region Move Account Balance
  private handleMoveAccountBalanceSuccess() {
    this.toastrService.success(this.translateService.instant('shared.common.transfer-successful'));
  }

  private handlerMoveAccountBalanceError(error: ApiError | null) {
    if (!error) {
      return;
    }

    this.toastrService.error(this.translateService.instant('error.temporary-server'));
  }
  //endregion

  //region User settings
  getPortfolioSettings() {
    return this.getLocaleStorageItem(PORTFOLIO_SETTINGS_LOCAL_STORAGE_KEY);
  }

  setPortfolioSettings(settings: Partial<PortfolioUserSettings>) {
    this.setLocaleStorageItem(PORTFOLIO_SETTINGS_LOCAL_STORAGE_KEY, settings);
  }

  private getLocaleStorageItem(storageKey: string) {
    try {
      const settings = window?.localStorage?.getItem(storageKey);
      return settings === null ? {} : JSON.parse(settings);
    } catch (error) {
      return {};
    }
  }

  private setLocaleStorageItem(storageKey: string, settings: Record<string, any>) {
    const currentSettings = this.getLocaleStorageItem(storageKey);
    window?.localStorage?.setItem(storageKey, JSON.stringify({ ...currentSettings, ...settings }));
  }
  //endregion
}
