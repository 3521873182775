<div
  *ngIf="showMessage"
  [ngClass]="{
    'alert-info': type === 'info',
    'alert-warning': type === 'warning',
    'alert-error': type === 'error',
    'alert-success': type === 'success'
  }"
  class="rounded-lg overflow-visible p-6"
>
  <ng-container *ngIf="showTitleSection">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <svg-icon *ngIf="type" [src]="'/assets/icons/icon-infobox-' + type + '.svg'"></svg-icon>
        <h3 *ngIf="title" class="ml-4 text-cm-blue-900 font-bold text-lg leading-6">{{ title }}</h3>
      </div>
      <button *ngIf="!hideCloseButton" (click)="showMessage = false">
        <svg-icon src="/assets/icons/icon-infobox-close.svg"></svg-icon>
      </button>
    </div>
  </ng-container>
  <p *ngIf="message" [innerHTML]="message" class="message text-cm-blue-900 font-normal text-base leading-5"></p>
</div>
