<div class="tooltip-group group" #tooltipTarget>
  <ng-content></ng-content>
  <div
    class="tooltip-content {{ tooltipClass }} {{ appearance }} {{ displayDot ? 'dot' : '' }}"
    [ngClass]="{
      'position-top': tooltipPosition === tooltipPositionEnum.top,
      'position-bottom': tooltipPosition === tooltipPositionEnum.bottom,
      'position-left': tooltipPosition === tooltipPositionEnum.left,
      'position-right': tooltipPosition === tooltipPositionEnum.right
    }"
    [style.left.px]="left"
    [style.top.px]="top"
    #tooltipContent
  >
    <span [innerHTML]="text"></span>
  </div>
</div>
