import { createReducer, on } from '@ngrx/store';
import { ApiStateStatus, initialPortfolioState } from '@app/authenticated/portfolio/store/portfolio.state';
import * as PortfolioActions from '@app/authenticated/portfolio/store/portfolio.actions';
import { ApiError } from '@app/shared/api/error.api';
import { AccountUpdateReason } from '@app/authenticated/portfolio/ui/portfolio.enum';

const parseApiError = (error: any): ApiError => ({
  message: error?.error?.message,
  code: error?.error?.code,
  business: error?.error?.business,
  errorType: error?.error?.errorType,
  additionalInfo: error?.error?.additionalInfo,
});

export const portfolioFeatureKey = 'portfolio';

export const reducer = createReducer(
  initialPortfolioState,

  //region Reset store
  on(PortfolioActions.resetStore, (state, action) => {
    return {
      ...initialPortfolioState,
    };
  }),
  //endregion

  //region Account ID
  on(PortfolioActions.setSelectedAccount, (state, action) => {
    return {
      ...state,
      accountId: action.accountId ? Number(action.accountId) : null,
    };
  }),
  //endregion

  //region Accounts
  on(PortfolioActions.loadAccountsLoading, (state) => ({
    ...state,
    accounts: {
      ...state.accounts,
      status: ApiStateStatus.loading,
    },
  })),
  on(PortfolioActions.loadAccountsSuccess, (state, action) => ({
    ...state,
    accounts: {
      ...state.accounts,
      data: action.data,
      error: null,
      status: ApiStateStatus.success,
    },
  })),
  on(PortfolioActions.loadAccountsFailure, (state, action) => ({
    ...state,
    accounts: {
      ...state.accounts,
      error: parseApiError(action.error),
      status: ApiStateStatus.error,
    },
  })),
  //endregion

  //region Update Account
  on(PortfolioActions.updateAccountLoading, (state, action) => ({
    ...state,
    updateAccount: state.updateAccount
      .filter(
        (updateAccountState) =>
          updateAccountState.meta.requestId !== action.meta.requestId &&
          updateAccountState.meta.reason !== action.meta.reason,
      )
      .concat({
        meta: action.meta,
        error: null,
        status: ApiStateStatus.loading,
      }),
  })),
  on(PortfolioActions.updateAccountSuccess, (state, action) => {
    let updatedAccountsData = state.accounts.data || [];

    if (action.meta.reason === AccountUpdateReason.createAccount) {
      updatedAccountsData = updatedAccountsData.concat(action.data);
    } else if (action.meta.reason === AccountUpdateReason.deactivateAccount) {
      updatedAccountsData = updatedAccountsData.filter((account) => account.id !== action.data.id);
    } else {
      updatedAccountsData = updatedAccountsData.map((account) => {
        if (account.id === action.data.id) {
          return {
            ...account,
            ...action.data,
          };
        }
        return account;
      });
    }

    return {
      ...state,
      accounts: {
        ...state.accounts,
        data: updatedAccountsData,
      },
      updateAccount: state.updateAccount.filter(
        (updateAccountState) =>
          updateAccountState.meta.requestId !== action.meta.requestId &&
          updateAccountState.meta.reason !== action.meta.reason,
      ),
    };
  }),
  on(PortfolioActions.updateAccountFailure, (state, action) => ({
    ...state,
    updateAccount: state.updateAccount.map((updateAccountState) => {
      if (
        updateAccountState.meta.requestId === action.meta.requestId &&
        updateAccountState.meta.reason === action.meta.reason
      ) {
        return {
          meta: updateAccountState.meta,
          error: parseApiError(action.error),
          status: ApiStateStatus.error,
        };
      }

      return updateAccountState;
    }),
  })),
  on(PortfolioActions.updateAccountDelete, (state, action) => ({
    ...state,
    updateAccount: state.updateAccount.filter(
      (updateAccountState) =>
        updateAccountState.meta.requestId !== action.meta.requestId &&
        updateAccountState.meta.reason !== action.meta.reason &&
        updateAccountState.status !== ApiStateStatus.loading,
    ),
  })),
  //endregion

  //region Account value history
  on(PortfolioActions.loadAccountValueHistoryLoading, (state) => ({
    ...state,
    accountsValueHistory: {
      ...state.accountsValueHistory,
      status: ApiStateStatus.loading,
    },
  })),
  on(PortfolioActions.loadAccountValueHistorySuccess, (state, action) => {
    const updatedData = (state.accountsValueHistory.data || []).concat();
    const accountHistoryIndex = updatedData.findIndex(({ id }) => id === action.data.id);

    if (accountHistoryIndex === -1) {
      updatedData.push(action.data);
    } else {
      updatedData.splice(accountHistoryIndex, 1, action.data);
    }

    return {
      ...state,
      accountsValueHistory: {
        data: updatedData,
        error: null,
        status: ApiStateStatus.success,
      },
    };
  }),
  on(PortfolioActions.loadAccountValueHistoryFailure, (state, action) => ({
    ...state,
    accountsValueHistory: {
      ...state.accountsValueHistory,
      error: action.error,
      status: ApiStateStatus.error,
    },
  })),
  //endregion

  //region Account value history total
  on(PortfolioActions.loadValueHistoryTotal, (state) => ({
    ...state,
    valueHistoryTotal: {
      ...state.valueHistoryTotal,
      status: ApiStateStatus.loading,
    },
  })),
  on(PortfolioActions.loadValueHistoryTotalSuccess, (state, action) => ({
    ...state,
    valueHistoryTotal: {
      ...state.valueHistoryTotal,
      data: action.data,
      error: null,
      status: ApiStateStatus.success,
    },
  })),
  on(PortfolioActions.loadValueHistoryTotalFailure, (state, action) => ({
    ...state,
    valueHistoryTotal: {
      ...state.valueHistoryTotal,
      error: action.error,
      status: ApiStateStatus.error,
    },
  })),
  //endregion

  //region Favorites
  on(PortfolioActions.loadFavorites, (state) => ({
    ...state,
    favorites: {
      ...state.favorites,
      error: null,
      status: ApiStateStatus.loading,
    },
  })),
  on(PortfolioActions.loadFavoritesSuccess, (state, action) => ({
    ...state,
    favorites: {
      ...state.favorites,
      data: action.favorites,
      status: ApiStateStatus.success,
    },
  })),
  on(PortfolioActions.loadFavoritesFailure, (state, action) => ({
    ...state,
    favorites: {
      ...state.favorites,
      error: action.error,
      status: ApiStateStatus.error,
    },
  })),
  //endregion
);
