/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeEmailDto } from '../models/change-email-dto';
import { CloseAccountDto } from '../models/close-account-dto';
import { UserProfileDto } from '../models/user-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class UserProfileControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProfileUsingGet
   */
  static readonly GetProfileUsingGetPath = '/frontend/user/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfileControllerService.GetProfileUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfileUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileUsingGet(params?: {
  },
  context?: HttpContext

): Observable<UserProfileDto> {

    return this.getProfileUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserProfileDto>) => r.body as UserProfileDto)
    );
  }

  /**
   * Path part for operation saveProfileUsingPost
   */
  static readonly SaveProfileUsingPostPath = '/frontend/user/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProfileUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProfileUsingPost$Response(params: {
    body: UserProfileDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfileControllerService.SaveProfileUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveProfileUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProfileUsingPost(params: {
    body: UserProfileDto
  },
  context?: HttpContext

): Observable<any> {

    return this.saveProfileUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation closeAccountUsingPost
   */
  static readonly CloseAccountUsingPostPath = '/frontend/user/profile/close-account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeAccountUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeAccountUsingPost$Response(params: {
    body: CloseAccountDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfileControllerService.CloseAccountUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeAccountUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeAccountUsingPost(params: {
    body: CloseAccountDto
  },
  context?: HttpContext

): Observable<any> {

    return this.closeAccountUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation changeLanguageRequestUsingPost
   */
  static readonly ChangeLanguageRequestUsingPostPath = '/frontend/user/profile/change-language';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeLanguageRequestUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeLanguageRequestUsingPost$Response(params: {
    lang: 'EN' | 'CS';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfileControllerService.ChangeLanguageRequestUsingPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeLanguageRequestUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeLanguageRequestUsingPost(params: {
    lang: 'EN' | 'CS';
  },
  context?: HttpContext

): Observable<any> {

    return this.changeLanguageRequestUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation createChangeEmailRequestUsingPost
   */
  static readonly CreateChangeEmailRequestUsingPostPath = '/frontend/user/profile/change-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createChangeEmailRequestUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createChangeEmailRequestUsingPost$Response(params: {
    body: ChangeEmailDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfileControllerService.CreateChangeEmailRequestUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createChangeEmailRequestUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createChangeEmailRequestUsingPost(params: {
    body: ChangeEmailDto
  },
  context?: HttpContext

): Observable<any> {

    return this.createChangeEmailRequestUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
