import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedState } from '@app-shared/store/shared.reducer';
import { queryUserInfo } from '@app-shared/store/user-info/user-info.selectors';
import { loadUserInfo } from '@app-shared/store/user-info/user-info.actions';

@Injectable({
  providedIn: 'root',
})
export class UserInfo {
  userInfo$ = this.store.select(queryUserInfo());
  eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private store: Store<SharedState>) {}

  load() {
    this.store.dispatch(loadUserInfo());
  }

  emitEvent(data: any) {
    this.eventEmitter.emit(data);
  }
}
