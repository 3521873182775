import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import BigNumber from 'bignumber.js';

import { ApiStateStatus, PortfolioState } from '@app/authenticated/portfolio/store/portfolio.state';
import * as fromPortfolio from '@app/authenticated/portfolio/store/portfolio.reducer';
import {
  createBalancesWithCurrencyEquivalentsSelector,
  BalanceWithCurrencyEquivalents,
} from '@app/shared/store/balances/balances.selectors';
import { queryCurrencies } from '@app/shared/store/currencies/currencies.selectors';

import { AccountUpdateReason, TimePeriod } from '@app/authenticated/portfolio/ui/portfolio.enum';
import { AccountTotalBalance, AccountValueHistoryByTimePeriod } from '@app/authenticated/portfolio/ui/portfolio.type';
import { SelectableCurrency } from '@app/shared/services/selected-currency.service';

import { CurrencyBalanceWithEquivalentsDto } from '@app/generated/models/currency-balance-with-equivalents-dto';
import { CurrencyWithBalance } from '@app/shared/components/balances-table/balances-table.component';
import { AccountValueHistoryDto } from '@app/generated/models/account-value-history-dto';
import { BalancesWrapperDto } from '@app/generated/models/balances-wrapper-dto';
import { CurrencyDto } from '@app/generated/models/currency-dto';

const balancesFilter =
  (allAvailableCurrencies: CurrencyDto[], onlyAvailable: boolean = false) =>
  ([currencyName, balance]: [string, { totalBalance: number }]) => {
    if (onlyAvailable && balance.totalBalance === 0) {
      return false;
    }

    const currency = allAvailableCurrencies.find(({ name }) => name === currencyName);
    return currency && currency.active;
  };

const selectPortfolioState = createFeatureSelector<PortfolioState>(fromPortfolio.portfolioFeatureKey);

//region Account ID
export const selectSelectedAccountId = createSelector(selectPortfolioState, (state) => state.accountId);
//endregion

//region Account ID parameterized selector factory
const createAccountIdFromParamSelector = (accountId: number | null) =>
  createSelector(() => accountId) as MemoizedSelector<object, number | null>;
//endregion

//region Accounts
export const selectAccountsData = createSelector(selectPortfolioState, (state) => state.accounts.data);

export const selectAccountsStatus = createSelector(selectPortfolioState, (state) => state.accounts.status);
export const selectAccountsError = createSelector(selectPortfolioState, (state) => state.accounts.error);

export const selectActiveAccount = createSelector(selectSelectedAccountId, selectAccountsData, (accountId, accounts) =>
  accounts?.find((account) => account.id === accountId),
);
//endregion

//region Update Account
const selectUpdateAccountData = createSelector(selectPortfolioState, (state) => state.updateAccount);
export const selectUpdateAccountStatus = (reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) => state.updateAccount.find((updateState) => updateState.meta.reason === reason)?.status || null,
  );
export const selectUpdateAccountError = (reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) => state.updateAccount.find((updateState) => updateState.meta.reason === reason)?.error || null,
  );
export const selectUpdateAccountStatusById = (requestId: string, reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) =>
      state.updateAccount.find(
        (updateState) => updateState.meta.requestId === requestId && updateState.meta.reason === reason,
      )?.status || null,
  );
export const selectUpdateAccountErrorById = (requestId: string, reason: AccountUpdateReason) =>
  createSelector(
    selectPortfolioState,
    (state) =>
      state.updateAccount.find(
        (updateState) => updateState.meta.requestId === requestId && updateState.meta.reason === reason,
      )?.error || null,
  );
//endregion

//region Account Balances total value
const computeAccountTotalBalancesValues = (accountBalances: BalancesWrapperDto) => {
  const accountTotalBalancesValues: Record<string, AccountTotalBalance> = {};

  Object.entries(accountBalances).forEach(([currencyName, balance]) => {
    const fiatEquivalents = balance.fiatEquivalents;
    if (accountTotalBalancesValues[currencyName]) {
      accountTotalBalancesValues[currencyName] = Object.entries(accountTotalBalancesValues[currencyName]).reduce(
        (acc: Record<string, number>, [fiatCurrencyName, value]) => {
          acc[fiatCurrencyName] = fiatEquivalents[fiatCurrencyName] + value;
          return acc;
        },
        {},
      ) as AccountTotalBalance;
    } else {
      accountTotalBalancesValues[currencyName] = fiatEquivalents;
    }
  });

  return accountTotalBalancesValues;
};

const computeAccountTotalBalancesValue = (accountTotalBalancesValues: Record<string, AccountTotalBalance>) => {
  const accountTotalBalancesValuesSum = {} as AccountTotalBalance;

  Object.values(accountTotalBalancesValues).forEach((accountTotalBalancesValues) => {
    Object.entries(accountTotalBalancesValues).forEach((accountTotalBalancesValue) => {
      const selectableCurrency = accountTotalBalancesValue[0] as SelectableCurrency;
      const value = accountTotalBalancesValue[1];

      if (accountTotalBalancesValuesSum[selectableCurrency]) {
        accountTotalBalancesValuesSum[selectableCurrency] = BigNumber(accountTotalBalancesValuesSum[selectableCurrency])
          .plus(value)
          .toNumber();
      } else {
        accountTotalBalancesValuesSum[selectableCurrency] = value;
      }
    });
  });

  return accountTotalBalancesValuesSum;
};

const selectAccountBalancesTotalValues = createSelector(
  selectAccountsData,
  selectSelectedAccountId,
  (accounts, selectedAccountId): Record<string, AccountTotalBalance> | {} => {
    const accountBalances = accounts?.find(({ id }) => {
      return selectedAccountId ? id === selectedAccountId : true;
    })?.balances;
    return accountBalances ? computeAccountTotalBalancesValues(accountBalances) : {};
  },
);

export const selectAccountsTotalValues = createSelector(
  selectAccountsData,
  (accounts): Record<number, AccountTotalBalance> => {
    if (!accounts) return {};

    return accounts.reduce((acc, account) => {
      const accountId = account.id as number;
      const accountBalances = account.balances;
      const accountTotalBalancesValues = accountBalances ? computeAccountTotalBalancesValues(accountBalances) : {};

      acc[accountId] = computeAccountTotalBalancesValue(accountTotalBalancesValues);
      return acc;
    }, {} as Record<number, AccountTotalBalance>);
  },
);

export const selectAccountsTotalValue = createSelector(
  selectAccountsTotalValues,
  (accountsTotalValues): AccountTotalBalance => {
    return computeAccountTotalBalancesValue(accountsTotalValues);
  },
);

export const selectAccountTotalValue = createSelector(
  selectSelectedAccountId,
  selectAccountsTotalValue,
  selectAccountsTotalValues,
  (selectedAccountId, accountsTotalValue, accountsTotalValues): AccountTotalBalance => {
    return selectedAccountId === null ? accountsTotalValue : accountsTotalValues[selectedAccountId];
  },
);
//endregion

//region Account balances selector factory
const createAccountBalancesSelector = (
  accountIdSelector: MemoizedSelector<object, number | null>,
  onlyAvailable?: boolean,
) =>
  createSelector(
    accountIdSelector,
    selectAccountsData,
    queryCurrencies(),
    (accountId, accounts, allAvailableCurrencies): Record<string, CurrencyBalanceWithEquivalentsDto> => {
      if (!accounts) {
        return {};
      }

      if (accountId === null) {
        return accounts.reduce((acc: Record<string, CurrencyBalanceWithEquivalentsDto>, account) => {
          if (!account?.balances) {
            return acc;
          }

          const accountBalances = Object.fromEntries(
            Object.entries(account.balances).filter(balancesFilter(allAvailableCurrencies, onlyAvailable)),
          ) as unknown as Record<string, CurrencyBalanceWithEquivalentsDto>;

          if (accountBalances) {
            for (const currencyName in accountBalances) {
              const currencyBalanceWithEquivalents = accountBalances[currencyName];

              for (const currencyBalanceWithEquivalentsKey in currencyBalanceWithEquivalents) {
                const key = currencyBalanceWithEquivalentsKey as keyof CurrencyBalanceWithEquivalentsDto;
                acc[currencyName] = acc[currencyName] || {};

                if (key === 'fiatEquivalents') {
                  const previousValues = acc[currencyName][key] || {};
                  const values = currencyBalanceWithEquivalents[key];
                  acc[currencyName][key] = Object.entries(values).reduce(
                    (acc: Record<string, number>, [valueKey, value]) => {
                      const previousValue = previousValues[valueKey] ?? 0;
                      acc[valueKey] = previousValue + value;
                      return acc;
                    },
                    {},
                  );
                } else {
                  const previousValue = acc[currencyName][key] ?? 0;
                  const value = currencyBalanceWithEquivalents[key];
                  acc[currencyName][key] = previousValue + value;
                }
              }
            }
          }
          return acc;
        }, {});
      }

      const account = accounts.find(({ id }) => id === accountId);
      if (account?.balances) {
        return Object.fromEntries(
          Object.entries(account.balances).filter(balancesFilter(allAvailableCurrencies, onlyAvailable)),
        );
      }

      return {};
    },
  );

//endregion

//region Only available balances for active account ID
export const selectOnlyAvailableAccountBalances = createSelector(
  createAccountBalancesSelector(selectSelectedAccountId, true),
  (accountBalances) => accountBalances,
);
//endregion

//region Account value history total
export const selectValueHistoryTotal = createSelector(selectPortfolioState, (state) => state.valueHistoryTotal.data);

export const selectValueHistoryTotalStatus = createSelector(
  selectPortfolioState,
  (state) => state.valueHistoryTotal.status,
);
export const selectValueHistoryTotalError = createSelector(
  selectPortfolioState,
  (state) => state.valueHistoryTotal.error,
);
//endregion

//region Account value histories
export const selectAccountsValueHistory = createSelector(
  selectPortfolioState,
  (state) => state.accountsValueHistory.data,
);

export const selectAccountsValueHistoryStatus = createSelector(
  selectPortfolioState,
  (state) => state.accountsValueHistory.status,
);
export const selectAccountsValueHistoryError = createSelector(
  selectPortfolioState,
  (state) => state.accountsValueHistory.error,
);

export const selectCombinedValuesHistoryStatus = createSelector(
  selectValueHistoryTotalStatus,
  selectAccountsValueHistoryStatus,
  (valueHistoryTotalStatus, accountsValueHistoryStatus) => {
    const statuses = [valueHistoryTotalStatus, accountsValueHistoryStatus];
    if (statuses.some((status) => status === ApiStateStatus.loading)) {
      return ApiStateStatus.loading;
    }
    if (statuses.some((status) => status === ApiStateStatus.loading)) {
      return ApiStateStatus.error;
    }
    return ApiStateStatus.pending;
  },
);
//endregion

//region Account value history for active account ID
export const selectAccountValueHistory = createSelector(
  selectValueHistoryTotal,
  selectAccountsValueHistory,
  selectSelectedAccountId,
  (accountsValueHistoriesTotal, accountsValueHistories, selectedAccountId) => {
    const accountValueHistory = selectedAccountId
      ? accountsValueHistories?.find(({ id }) => id === selectedAccountId)?.history
      : accountsValueHistoriesTotal;
    return accountValueHistory || null;
  },
);

export const selectAccountValueHistoryEvolutionByTimePeriod = (breakdown: TimePeriod) =>
  createSelector(
    selectAccountValueHistory,
    selectAccountTotalValue,
    (accountValueHistory, currentAccountValue): AccountValueHistoryDto[] | null => {
      if (!accountValueHistory || !currentAccountValue) return null;

      let startDayIndex = null;
      switch (breakdown) {
        case TimePeriod['24hours']: {
          startDayIndex = 2;
          break;
        }
        case TimePeriod['7days']: {
          startDayIndex = 7;
          break;
        }
        case TimePeriod['30days']: {
          startDayIndex = 30;
          break;
        }
        case TimePeriod['180days']: {
          startDayIndex = 180;
          break;
        }
      }

      const todayValues = {
        ...accountValueHistory[accountValueHistory.length - 1],
        czkValue: currentAccountValue.CZK,
        eurValue: currentAccountValue.EUR,
      };
      const accountValueWithUpdatedToday = accountValueHistory.concat();
      // No history is available for today's data, so the current portfolio value is used for today
      // BTC value is not updated!
      accountValueWithUpdatedToday.splice(-1, 1, todayValues);

      return accountValueWithUpdatedToday.slice(-startDayIndex);
    },
  );

export const selectAccountValueHistoryByTimePeriod = (breakdown: TimePeriod) =>
  createSelector(
    selectAccountValueHistoryEvolutionByTimePeriod(breakdown),
    (accountValueHistory): AccountValueHistoryByTimePeriod => {
      if (!accountValueHistory) return null;
      const startDay = accountValueHistory[0];
      const endDay = accountValueHistory[accountValueHistory.length - 1];

      const startDayCzkValueBN = BigNumber(startDay.czkValue ?? 0);
      const endDayCzkValueBN = BigNumber(endDay.czkValue ?? 0);
      const diffCzkValueBN = endDayCzkValueBN.minus(startDayCzkValueBN);

      const startDayEurValueBN = BigNumber(startDay.eurValue ?? 0);
      const endDayEurValueBN = BigNumber(endDay.eurValue ?? 0);
      const diffEurValueBN = endDayEurValueBN.minus(startDayEurValueBN);

      return {
        // TODO: 'valueCalculatedDate' is defined as string in the DTO, but it is actually a number
        startDay: startDay.valueCalculatedDate as unknown as number,
        endDay: endDay.valueCalculatedDate as unknown as number,
        CZK: {
          value: diffCzkValueBN.toNumber(),
          change: endDayCzkValueBN.gt(0) ? diffCzkValueBN.dividedBy(endDayCzkValueBN).multipliedBy(100).toNumber() : 0,
        },
        EUR: {
          value: diffEurValueBN.toNumber(),
          change: endDayEurValueBN.gt(0) ? diffEurValueBN.dividedBy(endDayEurValueBN).multipliedBy(100).toNumber() : 0,
        },
      };
    },
  );
//endregion

//region Favourites
export const selectAllFavorites = createSelector(selectPortfolioState, (state) => state.favorites.data || []);

export const selectAllFavoritesStatus = createSelector(selectPortfolioState, (state) => state.favorites.status);
export const selectAllFavoritesError = createSelector(selectPortfolioState, (state) => state.favorites.error);
//endregion

//region Enriched balances by currency equivalents for specific account ID
export const selectAccountBalancesWithCurrencyEquivalentsByAccountId = (accountId: number) =>
  createSelector(
    createBalancesWithCurrencyEquivalentsSelector(
      createAccountBalancesSelector(createAccountIdFromParamSelector(accountId)),
    ),
    selectAllFavorites,
    (currencyBalancesWithEquivalents, favourites): BalanceWithCurrencyEquivalents[] => currencyBalancesWithEquivalents,
  );
//endregion

//region Combined balances selector factory
const createAccountCombinedBalancesSelector = (
  accountBalancesSelector: MemoizedSelector<object, Record<string, CurrencyBalanceWithEquivalentsDto>>,
) =>
  createSelector(
    createBalancesWithCurrencyEquivalentsSelector(accountBalancesSelector),
    selectAllFavorites,
    (balancesPaired, favourites): CurrencyWithBalance[] | null => {
      return balancesPaired.map(
        (combinedAvailableBalance): CurrencyWithBalance => ({
          ...combinedAvailableBalance,
          favorite: favourites.includes(combinedAvailableBalance.name),
        }),
      );
    },
  );
//endregion

//region Combined balances for active account ID
export const selectAccountCombinedBalances = createSelector(
  createAccountCombinedBalancesSelector(createAccountBalancesSelector(selectSelectedAccountId)),
  (combinedAvailableBalances) => combinedAvailableBalances,
);
//endregion

//region Combined balances for specific account ID
export const selectAccountCombinedBalancesByAccountId = (accountId: number) =>
  createSelector(
    createAccountCombinedBalancesSelector(createAccountBalancesSelector(createAccountIdFromParamSelector(accountId))),
    (combinedAvailableBalances) => combinedAvailableBalances,
  );
//endregion

// region Combined balances for specific account ID - only available balances
export const selectAccountCombinedAvailableBalancesByAccountId = (accountId: number) =>
  createSelector(
    createAccountCombinedBalancesSelector(
      createAccountBalancesSelector(createAccountIdFromParamSelector(accountId), true),
    ),
    (combinedAvailableBalances) => combinedAvailableBalances,
  );
// endregion
