import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UserInfoControllerService } from '@app-generated/services/user-info-controller.service';
import { loadUserInfo, setUserInfo } from '@app-shared/store/user-info/user-info.actions';
import { initialState } from '@app/shared/store/user-info/user-info.reducer';

@Injectable()
export class UserInfoEffects {
  loadUserInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserInfo),
      mergeMap(() =>
        this.userInfoController.getInfoUsingGet().pipe(
          map((userInfo) => setUserInfo({ userInfo })),
          catchError(async () => setUserInfo({ userInfo: initialState })),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private userInfoController: UserInfoControllerService) {}
}
