import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { ApplicationRef, ErrorHandler, Injectable, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { Location } from '@angular/common';
import { ApiModule } from './generated/api.module';
import { environment } from './environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { supportedLanguages } from '@app-shared/const/locales.const';
import { ToastrModule } from 'ngx-toastr';
import { NgxCookiebotConfig, NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AlertMessageComponent } from '@app/shared/components/alert-message/alert-message.component';
import { default as pkg } from 'package.json';
import { BasicLayoutComponent } from '@app/layout/basic-layout/basic-layout.component';
import { DefaultLayoutComponent } from '@app/layout/default-layout/default-layout.component';
import { SseListenerService } from '@app/shared/services/sse-listener.service';
import { SecuredLayoutComponent } from '@app/layout/secured-layout/secured-layout.component';
import { SentryErrorHandlerService } from '@app/shared/services/sentry-error-handler.service';
import { AddHeadersInterceptor } from '@app/shared/interceptors/add-headers.interceptor';
import { DepositWithdrawalHttpInterceptor } from '@app/authenticated/deposits-withdrawals/interceptors/deposit-withdrawal.interceptor';
import { TradingPlatformLayoutComponent } from '@app/layout/trading-platform-layout/trading-platform-layout.component';
import { SecurityHttpInterceptor } from '@app/authenticated/security/interceptor/security.interceptor';

declare global {
  interface Window {
    cmConfig: { gtmId: string };
  }
}

const isBrowser = typeof window !== 'undefined';

export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, environment.baseUrl + '/assets/i18n/', '.json?v=' + pkg.version);

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  overrides = <any>{
    pan: { direction: 30 }, // Hammer.DIRECTION_ALL, avoid import because it fails in SSR
  };
  options = <any>{
    enable: isBrowser,
  };
}

const browserImports: Array<Type<any> | ModuleWithProviders<{}> | any[]> = [];
if (isBrowser) {
  class CookiebotConfig extends NgxCookiebotConfig {
    blockingMode = 'auto';
    cbId = '04440ac0-2c61-4103-9aff-7cb59de69ccc';
    loadScript = true;
    cdn = 'eu';
  }

  browserImports.push(HammerModule);
  browserImports.push(NgxCookiebotModule.forRoot(CookiebotConfig));
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    BasicLayoutComponent,
    SecuredLayoutComponent,
    TradingPlatformLayoutComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ApiModule.forRoot({ rootUrl: environment.apiRoot }),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: TimeagoIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate: any, location: any, settings: any) =>
          new ManualParserLoader(
            translate,
            location,
            settings,
            supportedLanguages.map((language) => language.key),
            'ROUTE',
          ),
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
      cacheMechanism: CacheMechanism.Cookie,
      cacheName: 'user.lang',
      cookieFormat: '{{value}};{{expires:365}};path=/;sameSite=Lax',
    }),
    ToastrModule.forRoot({
      timeOut: 20000,
      closeButton: true,
      preventDuplicates: true,
      enableHtml: true,
      resetTimeoutOnDuplicate: true,
      toastComponent: AlertMessageComponent,
    }),
    ...browserImports,
  ],
  providers: [
    // GTM ID in index.html
    { provide: 'googleTagManagerId', useValue: isBrowser ? window.cmConfig.gtmId : 'GTM-W489JX' },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptor,
      multi: true,
    },
    {
      provide: SseListenerService,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DepositWithdrawalHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private applicationRef: ApplicationRef) {
    // possibly helps with change detection (removal cause regression), but it's really weird and should be kept eye on
    // especially after angular updates check if can be removed
    if (isBrowser) {
      // only for non SSR
      applicationRef.isStable.pipe().subscribe((isStable) => {
        if (isStable) {
          applicationRef.tick();
        }

        setInterval(() => {
          applicationRef.tick();
        }, 100);
      });
    }
  }
}
