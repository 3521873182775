<ng-container *ngIf="selectedPair">
  <button class="pl-4 pr-0 font-bold flex items-center text-black hover:underline" (click)="openModal()">
    {{ selectedPair.name | appCurrencyPairDisplay | async }}
    <svg-icon
      *ngIf="!(this.currencySelectorModalState$ | async)?.show"
      class="ml-4 inline-block"
      src="/assets/icons/arrow-down.svg"
      [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
      #arrowDown
    ></svg-icon>
    <svg-icon
      *ngIf="(this.currencySelectorModalState$ | async)?.show"
      class="ml-4 inline-block"
      src="/assets/icons/arrow-up.svg"
      [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
      #arrowUp
    ></svg-icon>
  </button>
</ng-container>
