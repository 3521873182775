import { NgModule } from '@angular/core';

import { DropdownService } from '@app/shared/components/dropdown/dropdown.service';
import { DropdownComponent } from '@app/shared/components/dropdown/dropdown.component';
import { DropdownTriggerComponent } from '@app/shared/components/dropdown/dropdown-trigger/dropdown-trigger.component';
import { DropdownItemComponent } from '@app/shared/components/dropdown/dropdown-item/dropdown-item.component';
import { DropdownIconComponent } from '@app/shared/components/dropdown/dropdown-icon/dropdown-icon.component';

@NgModule({
  imports: [DropdownComponent, DropdownTriggerComponent, DropdownItemComponent, DropdownIconComponent],
  exports: [DropdownComponent, DropdownTriggerComponent, DropdownItemComponent, DropdownIconComponent],
  providers: [DropdownService],
})
export class DropdownModule {}
