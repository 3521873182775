/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountBalanceDto } from '../models/account-balance-dto';
import { OrderBookDto } from '../models/order-book-dto';
import { TradeDto } from '../models/trade-dto';

@Injectable({
  providedIn: 'root',
})
export class WebsocketTypingControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tradeUsingPost
   */
  static readonly TradeUsingPostPath = '/unused/trade';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tradeUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  tradeUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TradeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WebsocketTypingControllerService.TradeUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TradeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tradeUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tradeUsingPost(params?: {
  },
  context?: HttpContext

): Observable<Array<TradeDto>> {

    return this.tradeUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TradeDto>>) => r.body as Array<TradeDto>)
    );
  }

  /**
   * Path part for operation orderBookUsingPost
   */
  static readonly OrderBookUsingPostPath = '/unused/order-book';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderBookUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderBookUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<OrderBookDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WebsocketTypingControllerService.OrderBookUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderBookDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderBookUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderBookUsingPost(params?: {
  },
  context?: HttpContext

): Observable<Array<OrderBookDto>> {

    return this.orderBookUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<OrderBookDto>>) => r.body as Array<OrderBookDto>)
    );
  }

  /**
   * Path part for operation balancesUsingPost
   */
  static readonly BalancesUsingPostPath = '/unused/balances';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `balancesUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  balancesUsingPost$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
[key: string]: AccountBalanceDto;
}>> {

    const rb = new RequestBuilder(this.rootUrl, WebsocketTypingControllerService.BalancesUsingPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: AccountBalanceDto;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `balancesUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  balancesUsingPost(params?: {
  },
  context?: HttpContext

): Observable<{
[key: string]: AccountBalanceDto;
}> {

    return this.balancesUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: AccountBalanceDto;
}>) => r.body as {
[key: string]: AccountBalanceDto;
})
    );
  }

}
