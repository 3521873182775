<section *ngIf="showContent">
  <div class="container mb-12">
    <h1>Components Overview</h1>

    <h2>footer.component</h2>
    <h3>app-footer</h3>
    <app-footer></app-footer>
    <hr />

    <h2>footer-link.component</h2>
    <h3>app-footer-link</h3>
    <app-footer-link class="order-1 md:order-2 col" [columnData]="coinmate"></app-footer-link>
    <hr />

    <h2>input.component</h2>
    <h3>app-input</h3>
    <app-input type="text" class="verification-form__input" [placeholder]="'email'" [disabled]="false"></app-input>
    <hr />

    <h2>paragraph.component</h2>
    <h3>app-paragraph</h3>
    <app-paragraph [paragraph]="apiForBanksP"></app-paragraph>
    <hr />

    <h2>paragraph-with-img.component</h2>
    <h3>app-paragraph-with-img</h3>
    <app-paragraph-with-img
      [imgSrc]="'/assets/img/articles/api1.png'"
      [nicerHeader]="'API Integration'"
      [paragraph]="apiIntegrationP"
    >
    </app-paragraph-with-img>
    <hr />

    <h2>multi-choice-button-bar.component</h2>
    <h3>app-multi-choice-button-bar</h3>
    <app-multi-choice-button-bar [options]="filterOptions" [description]="'currency-pair-filter' | translate">
    </app-multi-choice-button-bar>
    <hr />

    <h2>language-switch.component</h2>
    <h3>app-language-switch</h3>
    <app-language-switch [type]="languageSwitchTypeEnum.menu"></app-language-switch>
    <br />
    <app-language-switch [type]="languageSwitchTypeEnum.footer"></app-language-switch>
    <hr />

    <h2>h.component</h2>
    <h3>app-h</h3>
    <app-h type="h1" [subtitle]="'title h1'"></app-h>
    <app-h type="h2" [subtitle]="'title h2'"></app-h>
    <app-h type="h3" [subtitle]="'title h3'"></app-h>
    <app-h type="h4" [subtitle]="'title h4'"></app-h>
    <hr />

    <h2>logged-button.component</h2>
    <h3>app-logged-button</h3>
    <br />
    <app-logged-button
      appearance="cm-currency-buy"
      text="currencies.table.purchase"
      addressHref="/quick-trade"
      link="/sign-up"
    >
      <img src="/assets/img/arrow-right-blue.svg" alt="{{ 'currencies.table.purchase' | translate }}" />
    </app-logged-button>
    <br />
    <app-logged-button
      appearance="cm-button cm-button-blue inline-block w-full md:!w-fit !px-[40px] !py-[20px] mt-[20px] cursor-pointer"
      link="/sign-up"
      addressHref="/quick-trade"
      [text]="'Button text'"
    ></app-logged-button>
    <hr />

    <h2>search.component</h2>
    <h3>app-search</h3>
    <app-search [searchPlaceholder]="'quick-trade.main.search' | translate"> </app-search>
    <hr />

    <h2>link-pair.component</h2>
    <h3>app-link-pair</h3>
    <app-logged-link-pair
      appearance="no-label"
      routerLink="/support"
      href="/customer-support"
      addressLabel="{{ 'bankid-error.support' | translate }}"
      addressIcon="/assets/img/arrow-right-blue.svg"
    ></app-logged-link-pair>
    <hr />

    <h2>currency-pair.component</h2>
    <h3>app-currency-pair</h3>
    <app-currency-pair type="remove" [currencyPair]="mockCurrencyPair" [isButtonDisplayed]="true"></app-currency-pair>
    <hr />

    <h2>or.component</h2>
    <h3>app-or</h3>
    <app-or></app-or>
    <hr />

    <h2>paragraph-box.component</h2>
    <h3>app-text</h3>
    <app-text class="app-text" [data]="'text'"></app-text>
    <app-text class="app-text" [data]="'text large'" size="large"></app-text>
    <hr />

    <h2>colored-value-text.component</h2>
    <h3>app-colored-currency-value-text</h3>
    <div class="flex gap-6 mb-12">
      <div>
        Original value
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appColoredCurrencyValueNegative }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appColoredCurrencyValueNearlyZero }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appColoredCurrencyValuePositive }}</div>
      </div>
      <div>
        Currency: unknown
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNegative"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNearlyZero"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValuePositive"
        ></app-colored-currency-value-text>
      </div>
      <div>
        Currency: CZK
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNegative"
          [currency]="'CZK'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNearlyZero"
          [currency]="'CZK'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValuePositive"
          [currency]="'CZK'"
        ></app-colored-currency-value-text>
      </div>
      <div>
        Currency: BTC
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNegative"
          [currency]="'BTC'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValueNearlyZero"
          [currency]="'BTC'"
        ></app-colored-currency-value-text>
        <app-colored-currency-value-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [value]="appColoredCurrencyValuePositive"
          [currency]="'BTC'"
        ></app-colored-currency-value-text>
      </div>
    </div>

    <h3>app-colored-percent-change-text</h3>
    <div class="flex gap-6">
      <div>
        Original value
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appPercentChangeValueNegative }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appPercentChangeValueNearlyZero }}</div>
        <div class="block font-bold text-[14px] lg:text-[16px] text-right">{{ appPercentChangeValuePositive }}</div>
      </div>
      <div>
        Decimals places: 2 <i>(default)</i>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
        ></app-colored-percent-change-text>
      </div>
      <div>
        Decimal places: 3
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
          [decimalPlaces]="3"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
          [decimalPlaces]="3"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
          [decimalPlaces]="3"
        ></app-colored-percent-change-text>
      </div>
      <div>
        Decimal places: 1
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
          [decimalPlaces]="1"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
          [decimalPlaces]="1"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
          [decimalPlaces]="1"
        ></app-colored-percent-change-text>
      </div>
      <div>
        Decimal places: 0
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNegative"
          [decimalPlaces]="0"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValueNearlyZero"
          [decimalPlaces]="0"
        ></app-colored-percent-change-text>
        <app-colored-percent-change-text
          class="block font-bold text-[14px] lg:text-[16px] text-right"
          [change]="appPercentChangeValuePositive"
          [decimalPlaces]="0"
        ></app-colored-percent-change-text>
      </div>
    </div>
    <hr />

    <h2>phone-number-input.component</h2>
    <h3>app-phone-number-input</h3>
    <app-phone-number-input></app-phone-number-input>
    <hr />

    <h2>benefit.component</h2>
    <h3>app-benefit</h3>
    <app-benefit
      heading="{{ 'cm-homepage.benefits.optimized.heading' }}"
      text="{{ 'cm-homepage.benefits.optimized.description' }}"
      icon="/assets/icons/icon-cz-flag.svg"
    ></app-benefit>
    <hr />

    <h2 id="app-balances-table">balances-table.component</h2>
    <h3>app-balances-table</h3>
    <div class="flex flex-col gap-6">
      <div>
        <strong>Deposits & Withdrawals</strong>
        <app-balances-table
          [data]="balancesTableData$ | async"
          [loading]="(balancesTableStatus$ | async) === 'loading'"
          (addFavorite)="onAddFavorite($event)"
          (removeFavorite)="onRemoveFavorite($event)"
        >
          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellBalance>
            <p>
              <strong>{{ currency.available | appCurrencyFloor: currency.name | async }} {{ currency.name }}</strong>
              <span *ngIf="currency.virtual"
                >≈
                {{ currency.fiatEquivalents[selectedFiatCurrency] | appCurrencyFloor: selectedFiatCurrency | async }}
                {{ selectedFiatCurrency }}
              </span>
            </p>
          </ng-template>

          <ng-template let-currency #actionButtons>
            <ng-container *ngIf="currency.virtual">
              <app-action-button [size]="'small'">
                {{ 'balances-table.deposit' | translate }}
              </app-action-button>
              <app-action-button [size]="'small'" [facet]="'tertiary'" [disabled]="currency.available === 0">
                {{ 'balances-table.withdrawal' | translate }}
              </app-action-button>
            </ng-container>
          </ng-template>
        </app-balances-table>
        <button (click)="reloadBalancesTable()">Reload</button>
      </div>
      <div>
        <strong>Portfolio</strong>
        <app-balances-table
          [data]="balancesTableData$ | async"
          [loading]="(balancesTableStatus$ | async) === 'loading'"
          (addFavorite)="onAddFavorite($event)"
          (removeFavorite)="onRemoveFavorite($event)"
        >
          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellBalance>
            <p>
              <strong>{{ currency.available | appCurrencyFloor: currency.name | async }} {{ currency.name }}</strong>
              <span *ngIf="currency.virtual"
                >≈
                {{ currency.fiatEquivalents[selectedFiatCurrency] | appCurrencyFloor: selectedFiatCurrency | async }}
                {{ selectedFiatCurrency }}
              </span>
            </p>
          </ng-template>

          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellPrice>
            <p *ngIf="currency.virtual">
              <strong>
                {{ currency.lastPrices[selectedFiatCurrency] | appCurrencyFloor: selectedFiatCurrency | async }}
                {{ selectedFiatCurrency }}
              </strong>
            </p>
          </ng-template>

          <ng-template let-currency let-selectedFiatCurrency="selectedFiatCurrency" #cellChange>
            <p *ngIf="currency.virtual && currency.changeIn24Hours">
              <strong>
                <app-colored-percent-change-text
                  [change]="currency.changeIn24Hours[selectedFiatCurrency]"
                ></app-colored-percent-change-text>
              </strong>
            </p>
          </ng-template>

          <ng-template let-currency #actionButtons>
            <app-action-button [size]="'small'">
              {{ 'balances-table.buy' | translate }}
            </app-action-button>
            <app-action-button [size]="'small'" [facet]="'tertiary'" [disabled]="currency.available === 0">
              {{ 'balances-table.sell' | translate }}
            </app-action-button>
          </ng-template>

          <ng-template
            let-currency
            let-depositBlocked="depositBlocked"
            let-depositVerified="depositVerified"
            let-withdrawalBlocked="withdrawalBlocked"
            #actionDropdown
          >
            <app-dropdown>
              <button dropdown-trigger>
                <svg-icon [src]="'/assets/icons/icon-dots-vertical.svg'"></svg-icon>
              </button>
              <button *ngIf="currency.virtual" dropdown-item>
                {{ 'balances-table.trade' | translate }}
              </button>
              <button [disabled]="depositBlocked || (!depositVerified && currency.virtual)" dropdown-item>
                {{ 'balances-table.deposit' | translate }}
              </button>
              <button [disabled]="withdrawalBlocked || !depositVerified || currency.available === 0" dropdown-item>
                {{ 'balances-table.withdrawal' | translate }}
              </button>
            </app-dropdown>
          </ng-template>
        </app-balances-table>
        <button (click)="reloadBalancesTable()">Reload</button>
      </div>
    </div>
    <hr />

    <h2>currencies-table.component</h2>
    <h3>app-currencies-table</h3>
    <app-currencies-table [isMinimal]="true"></app-currencies-table>
    <hr />

    <h2>action-button.component</h2>
    <h3>app-action-button</h3>
    <div class="flex gap-4">
      <div class="flex flex-col gap-2">
        <app-action-button type="submit" label="shared.common.log-in" [waiting]="true"></app-action-button>
        <app-action-button type="submit" label="shared.common.log-in" [waiting]="false"></app-action-button>

        <app-action-button type="submit" label="shared.common.log-in">
          <svg-icon src="/assets/icons/icon-trezor.svg"></svg-icon>
        </app-action-button>
        <app-action-button
          type="submit"
          label="shared.common.log-in"
          progressLabel="shared.common.loading"
          [waiting]="true"
        ></app-action-button>
      </div>
      <div class="flex flex-col gap-2">
        <app-action-button type="submit" label="shared.common.log-in" size="small" [waiting]="true"></app-action-button>
        <app-action-button
          type="submit"
          label="shared.common.log-in"
          size="small"
          [waiting]="false"
        ></app-action-button>
        <app-action-button type="submit" label="shared.common.log-in" size="small">
          <svg-icon src="/assets/icons/icon-trezor.svg"></svg-icon>
        </app-action-button>
        <app-action-button
          type="submit"
          label="shared.common.log-in"
          progressLabel="shared.common.loading"
          size="small"
          [waiting]="true"
        ></app-action-button>
      </div>
    </div>
    <hr />

    <h2>checkbox.component</h2>
    <h3>app-checkbox</h3>
    <app-checkbox id="newsletter" formControlName="newsletter" label="{{ 'signUp.subscribe' }}"></app-checkbox>
    <hr />

    <h2>qt-currency-input.component</h2>
    <h3>app-qt-currency-input</h3>
    <app-qt-currency-input
      [readOnly]="false"
      [currency]="mockFromCurrency"
      class="md:mb-8"
      formControlName="from"
    ></app-qt-currency-input>
    <hr />

    <h2>textarea.component</h2>
    <h3>app-textarea</h3>
    <app-textarea
      formControlName="tradingBotDetails"
      class="verification-form__input verification-form__input--full-width"
      placeholder="Provide us any other details relating to your trading bots."
      [isValid]="true"
    >
    </app-textarea>
    <hr />

    <h2>select.component</h2>
    <h3>app-select</h3>
    <app-select
      formControlName="occupation"
      class="verification-form__input"
      errorMessage="error.required-field"
      [isValid]="true"
      [autofocus]="true"
    >
      <option>
        {{ 'SELF_EMPLOYED' | translate }}
      </option>
    </app-select>
    <hr />

    <h2>select.component</h2>
    <h3>app-select-new</h3>
    <app-select-new formControlName="countryCode" id="country" label="{{ 'Country' | translate }}"> </app-select-new>
    <hr />

    <h2>textarea-new.component</h2>
    <h3>app-textarea-new</h3>
    <app-textarea-new formControlName="message" label="contact-form.message-label"></app-textarea-new>
    <hr />

    <h2>tooltip.component</h2>
    <h3>app-tooltip[text]</h3>
    <div class="flex flex-col gap-8">
      <code>[appearance]="cm"</code>
      <div class="flex flex-col gap-8 ml-4">
        <div>
          <code class="mb-6">[usePopover]="true"</code>
          <div class="flex gap-2">
            <div>
              Bottom position <i>(default)</i>:&nbsp;
              <app-tooltip [text]="'Tooltip'" [appearance]="'cm'" [usePopover]="true" [displayDot]="true">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Top position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [appearance]="'cm'"
                [usePopover]="true"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.top"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Left position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [appearance]="'cm'"
                [usePopover]="true"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.left"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Right position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [appearance]="'cm'"
                [usePopover]="true"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.right"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
          </div>
        </div>
        <div>
          <code class="mb-6">[usePopover]="false"</code>
          <div class="flex gap-2">
            <div>
              Bottom position <i>(default)</i>:&nbsp;
              <app-tooltip [text]="'Tooltip'" [appearance]="'cm'" [displayDot]="true">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Top position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [appearance]="'cm'"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.top"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Left position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [appearance]="'cm'"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.left"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Right position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [appearance]="'cm'"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.right"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
          </div>
        </div>
      </div>
      <code>[appearance]="default"</code>
      <div class="flex flex-col gap-8 ml-4">
        <div>
          <code class="mb-6">[usePopover]="true"</code>
          <div class="flex gap-2">
            <div>
              Bottom position <i>(default)</i>:&nbsp;
              <app-tooltip [text]="'Tooltip'" [usePopover]="true" [displayDot]="true">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Top position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [usePopover]="true"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.top"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Left position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [usePopover]="true"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.left"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Right position:&nbsp;
              <app-tooltip
                [text]="'Tooltip'"
                [usePopover]="true"
                [displayDot]="true"
                [tooltipPosition]="tooltipPositionEnum.right"
              >
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
          </div>
        </div>
        <div>
          <code class="mb-6">[usePopover]="false"</code>
          <div class="flex gap-2">
            <div>
              Bottom position <i>(default)</i>:&nbsp;
              <app-tooltip [text]="'Tooltip'" [displayDot]="true">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Top position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [displayDot]="true" [tooltipPosition]="tooltipPositionEnum.top">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Left position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [displayDot]="true" [tooltipPosition]="tooltipPositionEnum.left">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
            <div>
              Right position:&nbsp;
              <app-tooltip [text]="'Tooltip'" [displayDot]="true" [tooltipPosition]="tooltipPositionEnum.right">
                <button class="currency-pair__add-button">
                  <img
                    src="/assets/icons/icon-add-dark.svg"
                    width="18"
                    height="18"
                    class="currency-pair__theme-icon--dark"
                  />
                </button>
              </app-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <h2>otp-input.component</h2>
    <h3>app-otp-input</h3>
    <app-otp-input></app-otp-input>
    <hr />

    <h2>copy-to-clipboard.component</h2>
    <h3>app-copy-to-clipboard</h3>
    <app-copy-to-clipboard class="refLinkCopy copy-input" [toCopy]="'referralCode'"></app-copy-to-clipboard>
    <hr />

    <h2>confirmation-dialog.component</h2>
    <h3>app-confirmation-dialog</h3>
    <br />
    <button (click)="toggleConfirmationDialog()">Confirmation - click me!</button>
    <hr />

    <h2 id="app-dropdown">dropdown.component</h2>
    <h3>app-dropdown</h3>
    <div class="flex gap-2">
      <div>
        <app-dropdown>
          <button dropdown-trigger>Bottom-end position&nbsp;<i>(default)</i></button>
          <button dropdown-item>Item 1</button>
          <button dropdown-item>
            Item 2
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
            Item 3
          </button>
          <button dropdown-item>
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            Item 4
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
          </button>
          <button dropdown-item disabled>
            Item 5
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>Lorem ipsum dolor sit amet</button>
        </app-dropdown>
      </div>
      <div>
        <app-dropdown [popoverPosition]="popoverPositionEnum.bottomStart">
          <button dropdown-trigger>Bottom-start position</button>
          <button dropdown-item>Item 1</button>
          <button dropdown-item>
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            Item 2
          </button>
          <button dropdown-item>
            Item 3
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            Item 4
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
          </button>
          <button dropdown-item disabled>
            Item 5
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>Lorem ipsum dolor sit amet</button>
        </app-dropdown>
      </div>
      <div>
        <app-dropdown [popoverPosition]="popoverPositionEnum.topEnd">
          <button dropdown-trigger>Top-end position</button>
          <button dropdown-item>Item 1</button>
          <button dropdown-item>
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            Item 2
          </button>
          <button dropdown-item>
            Item 3
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            Item 4
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
          </button>
          <button dropdown-item disabled>
            Item 5
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>Lorem ipsum dolor sit amet</button>
        </app-dropdown>
      </div>
      <div>
        <app-dropdown [popoverPosition]="popoverPositionEnum.topStart">
          <button dropdown-trigger>Top-start position</button>
          <button dropdown-item>Item 1</button>
          <button dropdown-item>
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            Item 2
          </button>
          <button dropdown-item>
            Item 3
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
            Item 4
            <span dropdown-icon-right><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon></span>
          </button>
          <button dropdown-item disabled>
            Item 5
            <span dropdown-icon-left><svg-icon [src]="'/assets/icons/icon-wallet.svg'"></svg-icon></span>
          </button>
          <button dropdown-item>Lorem ipsum dolor sit amet</button>
        </app-dropdown>
      </div>
      <div>
        <app-dropdown [popoverClass]="'md:min-w-[328px]'">
          <button dropdown-trigger>Custom content</button>
          <div>
            <p class="text-large mb-6"><strong>Přepínání účtů</strong></p>
            <p class="mb-4">V rámci této stránky nelze přepínat mezi účty. Použijte svislou navigaci po levé straně.</p>
            <app-action-button [size]="'small'">Rozumím</app-action-button>
          </div>
        </app-dropdown>
      </div>
    </div>
    <hr />

    <h2>message.component</h2>
    <h3>app-message</h3>
    <app-message [showMessage]="true" [message]="'quick-trade.main.error.no-verified' | translate"> </app-message>
    <hr />

    <h2>menu.component</h2>
    <h3>app-menu</h3>
    <app-menu></app-menu>
    <br />
    <app-menu [isBasic]="true"></app-menu>
    <hr />

    <h2 id="app-menu-vertical">menu-vertical.component</h2>
    <h3>app-menu-vertical</h3>
    <div class="flex flex-col md:flex-row gap-6">
      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <a href="#" title="Components Overview button" menu-vertical-item>Anchor default</a>
            <a href="#" [facet]="'secondary'" menu-vertical-item>Anchor secondary</a>
            <a href="#" [facet]="'tertiary'" menu-vertical-item>Anchor tertiary</a>

            <a href="#" title="Components Overview button" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor default
            </a>
            <a href="#" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor secondary
            </a>
            <a href="#" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor tertiary
            </a>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button [facet]="'tertiary'" title="Button tertiary" menu-vertical-item>Button tertiary</button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <button title="Button default" menu-vertical-item>Button default</button>
            <button title="Button secondary" [facet]="'secondary'" menu-vertical-item>Button secondary</button>
            <button title="Button tertiary" [facet]="'tertiary'" menu-vertical-item>Button tertiary</button>

            <button title="Button default" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button default
            </button>
            <button title="Button secondary" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button secondary
            </button>
            <button title="Button tertiary" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button tertiary
            </button>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary" [facet]="'tertiary'" menu-vertical-item>Button tertiary</button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <a href="/components-overview" title="Anchor default active" menu-vertical-item>Anchor default active</a>
            <a href="/components-overview" title="Anchor secondary active" [facet]="'secondary'" menu-vertical-item
              >Anchor secondary active</a
            >
            <a href="/components-overview" title="Anchor tertiary active" [facet]="'tertiary'" menu-vertical-item
              >Anchor tertiary active</a
            >

            <a href="/components-overview" title="Anchor default active" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor default active
            </a>
            <a href="/components-overview" title="Anchor secondary active" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor secondary active
            </a>
            <a href="/components-overview" title="Anchor tertiary active" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Anchor tertiary active
            </a>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary another section" [facet]="'tertiary'" menu-vertical-item>
              Button tertiary another section
            </button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <button title="Button default active" menu-vertical-item>Button default active</button>
            <button title="Button secondary active" [facet]="'secondary'" menu-vertical-item>
              Button secondary active
            </button>
            <button title="Button tertiary active" [facet]="'tertiary'" menu-vertical-item>
              Button tertiary active
            </button>

            <button title="Button default active" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button default active
            </button>
            <button title="Button secondary active" [facet]="'secondary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button secondary active
            </button>
            <button title="Button tertiary active" [facet]="'tertiary'" menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button tertiary active
            </button>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary another section" [facet]="'tertiary'" menu-vertical-item>
              Button tertiary another section
            </button>
          </div>
        </app-menu-vertical>
      </div>

      <div class="w-[180px]">
        <app-menu-vertical>
          <div menu-vertical-section>
            <button title="Button default active" disabled menu-vertical-item>Button default disabled</button>
            <button title="Button secondary disabled" [facet]="'secondary'" disabled menu-vertical-item>
              Button secondary disabled
            </button>
            <button title="Button tertiary disabled" [facet]="'tertiary'" disabled menu-vertical-item>
              Button tertiary disabled
            </button>

            <button title="Button default disabled" disabled menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button default disabled
            </button>
            <button title="Button secondary disabled" [facet]="'secondary'" disabled menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button secondary disabled
            </button>
            <button title="Button tertiary disabled" [facet]="'tertiary'" disabled menu-vertical-item>
              <svg-icon [src]="'/assets/icons/icon-plus-large.svg'"></svg-icon>
              Button tertiary disabled
            </button>
          </div>

          <div [justifyCenter]="true" menu-vertical-section>
            <button title="Button tertiary another section" [facet]="'tertiary'" disabled menu-vertical-item>
              Button tertiary another section
            </button>
          </div>
        </app-menu-vertical>
      </div>
    </div>
    <hr />

    <h2>public-with-header.component</h2>
    <h3>app-public-with-header</h3>
    <app-public-with-header
      headerImgMobile="/assets/img/headers/dash.m.png"
      headerImgDesktop="/assets/img/headers/dash.png"
      [headingText]="'pageName'"
      [headingSubText]="'pageHeader'"
    ></app-public-with-header>
    <hr />

    <h2>ToastrService</h2>
    <h3>app-alert-message</h3>
    <br /><br />
    <button (click)="showInfo()">Info Toast</button>
    <br /><br />
    <button (click)="showSuccess()">Success Toast</button>
    <br /><br />
    <button (click)="showError()">Error Toast</button>
    <hr />

    <h2>switch.component</h2>
    <h3>app-switch</h3>
    <div class="flex items-center gap-4">
      <app-switch label="Label" falseLabel="Off" trueLabel="On"></app-switch>
      <app-switch label="Label" falseLabel="Konkrétní měna" trueLabel="Všechny prostředky"></app-switch>
    </div>
    <hr />

    <h2>loader.component</h2>
    <h3>app-loader</h3>
    <app-loader></app-loader>
    <hr />

    <h2 id="app-accordion">accordion.component</h2>
    <h3>app-accordion</h3>
    <div class="flex gap-12">
      <div>
        <div class="mb-4">Multi = false <i>(default)</i></div>
        <app-accordion>
          <div [expanded]="true" app-accordion-item>
            <div app-accordion-header>
              {{ accordionHeaderText }}
            </div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
        </app-accordion>
      </div>
      <div>
        <div class="mb-4">Multi = true</div>
        <app-accordion [multi]="true">
          <div [expanded]="true" app-accordion-item>
            <div app-accordion-header>
              {{ accordionHeaderText }}
            </div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
          <div app-accordion-item>
            <div app-accordion-header>{{ accordionHeaderText }}</div>
            {{ accordionBodyText }}
          </div>
        </app-accordion>
      </div>
    </div>
  </div>

  <div class="container">
    <h1>Tailwind variants</h1>

    <h2>Typography</h2>
    <div class="grid grid-cols-2 gap-x-4 mt-8">
      <div class="mb-8">
        <strong>Used for secured pages</strong>
      </div>
      <div class="mb-8">
        <strong>Used for public pages</strong>
      </div>

      <div>
        <h3>.h1</h3>
        <div class="h1">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h1</h3>
        <div class="display-h1">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.h2</h3>
        <div class="h2">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h2</h3>
        <div class="display-h2">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.h3</h3>
        <div class="h3">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h3</h3>
        <div class="display-h3">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.h4</h3>
        <div class="h4">{{ typographyTextDemo }}</div>
      </div>
      <div>
        <h3>.display-h4</h3>
        <div class="display-h4">{{ typographyTextDemo }}</div>
      </div>
      <hr class="col-span-2" />

      <div class="col-start-2">
        <h3>.display-paragraph-blog</h3>
        <p class="display-paragraph-blog mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph-blog font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.paragraph-large</h3>
        <p class="paragraph-large mb-2">{{ typographyTextDemo }}</p>
        <p class="paragraph-large font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <div>
        <h3>.display-paragraph-large</h3>
        <p class="display-paragraph-large mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph-large font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.paragraph</h3>
        <p class="paragraph mb-2">{{ typographyTextDemo }}</p>
        <p class="paragraph font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <div>
        <h3>.display-paragraph</h3>
        <p class="display-paragraph mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div>
        <h3>.paragraph-small</h3>
        <p class="paragraph-small mb-2">{{ typographyTextDemo }}</p>
        <p class="paragraph-small font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <div>
        <h3>.display-paragraph-small</h3>
        <p class="display-paragraph-small mb-2">{{ typographyTextDemo }}</p>
        <p class="display-paragraph-small font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div class="col-start-2">
        <h3>.display-caption</h3>
        <p class="display-caption mb-2">{{ typographyTextDemo }}</p>
        <p class="display-caption font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />

      <div class="col-start-2">
        <h3>.display-caption-small</h3>
        <p class="display-caption-small mb-2">{{ typographyTextDemo }}</p>
        <p class="display-caption-small font-semibold">{{ typographyTextDemo }}</p>
      </div>
      <hr class="col-span-2" />
    </div>

    <h2>Buttons</h2>
    <h3>.button</h3>
    <div class="flex gap-12">
      <button class="button button-primary">{{ buttonPrimaryTextDemo }}</button>
    </div>
    <hr />
    <h3>.button-small</h3>
    <div class="flex gap-12">
      <button class="button button-small button-primary">{{ buttonPrimaryTextDemo }}</button>
    </div>
    <hr />
    <h3>.button-large</h3>
    <div class="flex gap-12">
      <button class="button button-large button-primary">{{ buttonPrimaryTextDemo }}</button>
    </div>
    <hr />
  </div>
</section>
