import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent } from '@app-shared/components/form/abstract/abstract.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

type TypeOptions = 'number' | 'text' | 'email' | 'password' | 'radio';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends AbstractComponent implements OnInit {
  @Input() type!: TypeOptions;
  @Input() placeholder?: string;
  @Input() size?: 'small' | 'medium' = 'medium';
  @Input() suggestedValue?: number | string;
  @Input() suggestedValueLabel?: string;
  @Input() infoLabel?: string;
  @Input() autocapitalize?: string; // affects behaviour only when typing on virtual keyboard
  @Input() forceAutofocus?: boolean; // e.g. for 2FA
  @Input() onInputCallback?: (arg0: any) => any;

  @Input() errorMessage?: string | undefined;
  @Input() isValid?: boolean;

  @ViewChild('input') inputElement!: ElementRef;

  isFocused = false;

  constructor() {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      if (this.forceAutofocus) {
        this.inputElement.nativeElement.focus();
      }
    }, 0);
  }

  get value() {
    return this.getTypedValue(super.value);
  }

  set value(value) {
    const typedValue = this.getTypedValue(value);
    super.value = typedValue;
    this.onChange(typedValue);
    this.onTouched();
  }

  getTypedValue(value: string | number): string | number {
    return this.type === 'number' && String(value).length > 0 ? Number(value) : value;
  }

  onInput() {
    if (this.onInputCallback) {
      this.onInputCallback(this.inputElement.nativeElement);
    }
  }
}
