<ng-container *ngIf="!loading; else loader">
  <ng-container *ngIf="data !== null">
    <div class="balances-table">
      <!-- SEARCH, FILTER -->
      <div class="flex items-center justify-between relative mb-[20px] z-[100]">
        <div class="w-full md:w-auto flex items-center">
          <form>
            <label for="search" class="sr-only">
              {{ 'balances-table.search-placeholder' | translate }}
            </label>
            <input
              type="text"
              name="search"
              id="search"
              (input)="onSearchTermChange($event)"
              [placeholder]="'balances-table.search-placeholder' | translate"
            />
            <div class="search">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.8061 19.8277L16.4765 15.4981C17.7318 13.9912 18.3577 12.0582 18.2241 10.1016C18.0906 8.14493 17.2078 6.31514 15.7594 4.99292C14.3111 3.6705 12.4084 2.95742 10.4477 3.00197C8.487 3.0465 6.61915 3.84528 5.23205 5.23205C3.84527 6.61896 3.04651 8.48703 3.00197 10.4477C2.95743 12.4085 3.6705 14.311 4.99292 15.7594C6.31521 17.2078 8.14506 18.0906 10.1016 18.2241C12.0584 18.3577 13.9912 17.7318 15.4981 16.4765L19.8277 20.8061C20.0034 20.9758 20.2556 21.0403 20.4911 20.9757C20.7267 20.9111 20.9107 20.7271 20.9754 20.4915C21.04 20.256 20.9758 20.0033 20.8061 19.8277ZM4.40724 10.6325C4.40724 8.98143 5.06312 7.3981 6.23058 6.23036C7.39816 5.06287 8.98165 4.40702 10.6327 4.40702C12.284 4.40702 13.8675 5.0629 15.0349 6.23036C16.2025 7.39794 16.8584 8.98143 16.8584 10.6325C16.8584 12.2837 16.2025 13.8673 15.0349 15.0347C13.8674 16.2023 12.284 16.8582 10.6327 16.8582C8.98226 16.8563 7.39993 16.1998 6.23283 15.0327C5.0656 13.8656 4.40901 12.2832 4.40724 10.6325Z"
                  fill="#060A1A"
                />
              </svg>
            </div>
          </form>
          <div class="applied-filter" *ngIf="showWithAmountOnly$ | async">
            <span>{{ 'balances-table.with-balance' | translate }}</span>
            <button (click)="onToggleWithAmountOnly()">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                  fill="#060A1A"
                />
              </svg>
            </button>
          </div>
          <div class="applied-filter" *ngIf="showFavoritesOnly$ | async">
            <span>
              {{ 'balances-table.favourites' | translate }}
            </span>
            <button (click)="onToggleFavoritesOnly()">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                  fill="#060A1A"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="relative">
          <ng-container *ngTemplateOutlet="filterDropdown"></ng-container>
        </div>
      </div>

      <!-- TABLE -->
      <div class="table-container">
        <ng-container *ngIf="filteredCurrencies.length; else noBalances">
          <table>
            <tr class="no-background no-border">
              <th colspan="3">
                {{ 'balances-table.currency' | translate }}
              </th>
              <th *ngIf="cellTotalBalanceRef" class="table-balance">
                {{ 'balances-table.total-balance' | translate }}
              </th>
              <th *ngIf="cellPriceRef" class="table-price">
                {{ 'balances-table.price' | translate }}
              </th>
              <th *ngIf="cellChangeRef" class="table-change">
                {{ 'balances-table.change' | translate }}
              </th>
              <th *ngIf="actionButtonsRef || actionDropdownRef" class="!hidden md:!table-cell"></th>
            </tr>

            <ng-container *ngIf="selectedFiatCurrency$ | async as selectedFiatCurrency">
              <ng-container *ngFor="let currency of filteredCurrencies; let i = index">
                <ng-container *ngIf="i === 0 && currency.favorite">
                  <tr class="no-background no-border">
                    <td colspan="5" class="table-section-title">
                      {{ 'balances-table.favourites' | translate }}
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="isStartOfNonFavorites(i, filteredCurrencies)">
                  <tr class="no-background no-border">
                    <td colspan="5" class="table-section-title">
                      {{ 'balances-table.other-currencies' | translate }}
                    </td>
                  </tr>
                </ng-container>
                <tr>
                  <td class="table-favorite">
                    <button class="like" [class.active]="currency.favorite" (click)="onToggleFavorite(currency)">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.39076 3.03273C9.58369 2.59991 9.68015 2.38351 9.81448 2.31686C9.93116 2.25896 10.0682 2.25896 10.1849 2.31686C10.3192 2.38351 10.4157 2.59991 10.6086 3.03273L12.1453 6.48017C12.2023 6.60812 12.2308 6.6721 12.275 6.72109C12.314 6.76444 12.3617 6.79911 12.415 6.82285C12.4753 6.84968 12.5449 6.85703 12.6842 6.87173L16.4378 7.2679C16.9091 7.31764 17.1447 7.34251 17.2496 7.44966C17.3407 7.54274 17.383 7.67307 17.364 7.80193C17.3422 7.95027 17.1661 8.10889 16.8141 8.42612L14.0103 10.9529C13.9062 11.0467 13.8542 11.0936 13.8212 11.1507C13.7921 11.2012 13.7738 11.2573 13.7677 11.3154C13.7608 11.3809 13.7754 11.4494 13.8044 11.5865L14.5876 15.2788C14.6859 15.7423 14.735 15.9741 14.6656 16.107C14.6052 16.2224 14.4943 16.3029 14.3659 16.3247C14.2181 16.3497 14.0128 16.2313 13.6023 15.9946L10.3328 14.1088C10.2114 14.0388 10.1507 14.0038 10.0862 13.9901C10.0292 13.978 9.97019 13.978 9.91312 13.9901C9.84862 14.0038 9.78794 14.0388 9.66659 14.1088L6.39702 15.9946C5.98654 16.2313 5.7813 16.3497 5.63345 16.3247C5.50503 16.3029 5.39416 16.2224 5.3338 16.107C5.2643 15.9741 5.31346 15.7423 5.41178 15.2788L6.19492 11.5865C6.22399 11.4494 6.23852 11.3809 6.23162 11.3154C6.22551 11.2573 6.20729 11.2012 6.17812 11.1507C6.14516 11.0936 6.09313 11.0467 5.98907 10.9529L3.18522 8.42612C2.83321 8.10889 2.6572 7.95027 2.63532 7.80193C2.61632 7.67307 2.65867 7.54274 2.74978 7.44966C2.85468 7.34251 3.0903 7.31764 3.56155 7.2679L7.31513 6.87173C7.45444 6.85703 7.5241 6.84968 7.58433 6.82285C7.63762 6.79911 7.68534 6.76444 7.72439 6.72109C7.76851 6.6721 7.79703 6.60812 7.85407 6.48017L9.39076 3.03273Z"
                          stroke="#3E68FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                  <td class="table-icon">
                    <img
                      class="h-8 w-8 rounded-full"
                      [src]="'/assets/crypto/color/' + currency.name.toLowerCase() + '.svg'"
                      alt="{{ currency.name }}"
                    />
                  </td>
                  <td class="table-currency">
                    <p>
                      <strong>{{ currency.displayName | translate }}</strong>
                      {{ currency.name }}
                    </p>
                  </td>
                  <td *ngIf="cellTotalBalanceRef" class="table-balance">
                    <ng-container
                      *ngTemplateOutlet="cellTotalBalanceRef; context: { $implicit: currency, selectedFiatCurrency }"
                    ></ng-container>
                  </td>
                  <td *ngIf="cellPriceRef" class="table-price">
                    <ng-container
                      *ngTemplateOutlet="cellPriceRef; context: { $implicit: currency, selectedFiatCurrency }"
                    ></ng-container>
                  </td>
                  <td *ngIf="cellChangeRef" class="table-change">
                    <ng-container
                      *ngTemplateOutlet="cellChangeRef; context: { $implicit: currency, selectedFiatCurrency }"
                    ></ng-container>
                  </td>
                  <td *ngIf="actionButtonsRef || actionDropdownRef" class="table-buttons">
                    <ng-container
                      *ngTemplateOutlet="
                        actionButtonsRef;
                        context: {
                          $implicit: currency,
                          depositBlocked,
                          depositVerified,
                          withdrawalBlocked
                        }
                      "
                    >
                    </ng-container>

                    <ng-container
                      *ngTemplateOutlet="
                        actionDropdownRef;
                        context: {
                          $implicit: currency,
                          depositBlocked,
                          depositVerified,
                          withdrawalBlocked
                        }
                      "
                    >
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </ng-container>
      </div>
    </div>

    <ng-template #filterDropdown>
      <app-dropdown class="filter-dropdown">
        <button dropdown-trigger>
          <svg-icon class="text-cm-blue" [src]="'/assets/icons/icon-filter-list.svg'"></svg-icon>
          <span *ngIf="filtersCount$ | async as showFilterButton" class="filters-count">{{ showFilterButton }}</span>
        </button>
        <button (click)="onToggleFavoritesOnly()" dropdown-item>
          {{ 'balances-table.favourites' | translate }}
          <span *ngIf="showFavoritesOnly$ | async" dropdown-icon-right
            ><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon
          ></span>
        </button>
        <button (click)="onToggleWithAmountOnly()" dropdown-item>
          {{ 'balances-table.with-balance' | translate }}
          <span *ngIf="showWithAmountOnly$ | async" dropdown-icon-right
            ><svg-icon [src]="'/assets/icons/icon-check.svg'"></svg-icon
          ></span>
        </button>
      </app-dropdown>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #noBalances
  ><div class="mb-[20px] text-center text-cm-grey-300 text-sm">
    {{ 'error.balances.no-available-balances' | translate }}
  </div></ng-template
>
