<app-base-modal
  (closeModal)="resetFilterOptions()"
  [modalId]="modalId"
  [modalTitle]="'currency-pairs-for-trading' | translate"
>
  <ng-container modal-header>
    <app-search
      [searchTerm]="searchTerm"
      [searchPlaceholder]="'quick-trade.main.search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    >
    </app-search>
    <br />
    <app-multi-choice-button-bar
      [options]="filterOptions"
      [description]="'currency-pair-filter' | translate"
      (selectedOptions)="handleSelectedOptions($event)"
    >
    </app-multi-choice-button-bar>
  </ng-container>
  <ng-container modal-body>
    <table class="min-w-full">
      <thead>
        <tr>
          <th
            *ngFor="let header of headers; let isFirst = first"
            class="py-2 text-[14px] leading-[20px] text-cm-grey-400 font-normal"
            [ngClass]="{ 'text-left': isFirst, 'text-right': !isFirst }"
          >
            {{ header | translate }}
          </th>
        </tr>
        <tr class="h-4"></tr>
      </thead>
      <ng-container *ngIf="listFavouritePairs() && listFavouritePairs().length">
        <thead>
          <tr>
            <th colspan="5" class="py-2 text-left text-cm-grey-400 tracking-wider font-sans text-[12px] font-normal">
              {{ 'favourites' | translate }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr *ngFor="let pair of listFavouritePairs()" (click)="onRowClick(pair)" class="cursor-pointer">
            <td class="px-0 py-4 whitespace-nowrap flex items-center">
              <div class="flex w-10 h-10 mr-0 cursor-pointer items-center" (click)="onIconClick($event, pair)">
                <svg-icon src="/assets/icons/icon-favorites-on.svg" class="w-5 h-5"></svg-icon>
              </div>
              <svg-icon
                [src]="'/assets/crypto/color/' + pair.firstCurrency.toLowerCase() + '.svg'"
                class="w-7 h-7 mr-6"
              ></svg-icon>
              <span class="text-[14px] lg:text-[16px] leading-[20px] font-semibold text-cm-blue-900">
                {{ pair.name | appCurrencyPairDisplay | async }}</span
              >
            </td>
            <td class="px-0 py-4 whitespace-nowrap text-right w-1/6">
              <div
                class="text-[14px] leading-[20px] font-medium"
                [ngClass]="{
                  'text-cm-grey-400': pair.changeIn24Hours === 0,
                  'text-red-600': pair.changeIn24Hours < 0,
                  'text-green-600': pair.changeIn24Hours > 0
                }"
              >
                {{ (+pair.changeIn24Hours).toFixed(2) }}%
              </div>
            </td>
            <td
              class="px-0 py-4 whitespace-nowrap text-[14px] sm:text-[16px] leading-[20px] font-semibold text-cm-blue-900 text-right"
            >
              {{ pair.lastPrice | appCurrencyFloor: pair.secondCurrency | async }} {{ pair.secondCurrency }}
            </td>
          </tr>
        </tbody>
      </ng-container>
      <thead
        *ngIf="listFavouritePairs() && listFavouritePairs().length && filteredRestPairs && filteredRestPairs.length"
      >
        <tr class="h-5"></tr>
        <tr>
          <th colspan="5" class="py-2 text-left text-cm-grey-400 tracking-wider font-sans text-[12px] font-normal">
            {{ 'others' | translate }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="filteredRestPairs && filteredRestPairs.length">
        <tr *ngFor="let pair of filteredRestPairs" (click)="onRowClick(pair)" class="cursor-pointer">
          <td class="px-0 py-4 whitespace-nowrap flex items-center">
            <div class="flex w-10 h-10 mr-0 cursor-pointer items-center" (click)="onIconClick($event, pair)">
              <svg-icon src="/assets/icons/icon-favorites.svg" class="w-5 h-5"></svg-icon>
            </div>
            <svg-icon
              [src]="'/assets/crypto/color/' + pair.firstCurrency.toLowerCase() + '.svg'"
              class="w-7 h-7 mr-6"
            ></svg-icon>
            <span class="text-[14px] lg:text-[16px] leading-[20px] font-semibold text-cm-blue-900">
              {{ pair.name | appCurrencyPairDisplay | async }}
            </span>
          </td>
          <td class="px-0 py-4 whitespace-nowrap text-right w-1/6">
            <div
              class="text-[14px] leading-[20px] font-medium"
              [ngClass]="{
                'text-cm-grey-400': pair.changeIn24Hours === 0,
                'text-red-600': pair.changeIn24Hours < 0,
                'text-green-600': pair.changeIn24Hours > 0
              }"
            >
              {{ (+pair.changeIn24Hours).toFixed(2) }}%
            </div>
          </td>
          <td
            class="px-0 py-4 whitespace-nowrap text-[14px] sm:text-[16px] leading-[20px] font-semibold text-cm-blue-900 text-right"
          >
            {{ pair.lastPrice | appCurrencyFloor: pair.secondCurrency | async }} {{ pair.secondCurrency }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</app-base-modal>
