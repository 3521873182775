<p class="{{ appearance ?? 'cm-paragraph' }}">
  <strong *ngIf="label">{{ label }}</strong>
  <a
    *ngIf="addressHref || openInNewWindow"
    class="{{ linkAppearance ?? 'cm-paragraph-address' }}"
    target="{{ openInNewWindow ? '_blank' : '_self' }}"
    href="{{ addressHref || (routerLink || '' | localize) | appSupport }}"
  >
    {{ addressLabel }}<img class="cm-paragraph-icon" src="{{ addressIcon }}" alt="Ikona" />
  </a>
  <a
    *ngIf="routerLink && !openInNewWindow"
    class="{{ linkAppearance ?? 'cm-paragraph-address' }}"
    target="_self"
    [routerLink]="[routerLink | localize | appSupport]"
    [fragment]="fragment"
  >
    {{ addressLabel }}<img class="cm-paragraph-icon" src="{{ addressIcon }}" alt="Ikona" />
  </a>
  <a *ngIf="onClick" class="{{ linkAppearance ?? 'cm-paragraph-address' }}" (click)="onClick()">
    {{ addressLabel }}<img class="cm-paragraph-icon" src="{{ addressIcon }}" alt="Ikona" />
  </a>
</p>
