<ng-content></ng-content>

<ng-container *ngIf="mobileMenuVerticalTriggerContentRef; else closeIcon">
  <ng-container
    *ngTemplateOutlet="mobileMenuVerticalTriggerContentRef; context: { $implicit: mobileMenuVerticalIsVisible }"
  ></ng-container>
</ng-container>

<ng-template #closeIcon>
  <svg-icon src="/assets/icons/icon-modal-close.svg"></svg-icon>
</ng-template>
