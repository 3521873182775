<div class="cm-alert-message" [ngClass]="getAlertLevelClass()">
  <div class="pb-1 text">
    <h3 [innerHTML]="title"></h3>
    <p [innerHTML]="message"></p>
  </div>
  <button class="close" (click)="remove()">
    <svg-icon src="/assets/icons/close-circle.svg"></svg-icon>
    <span class="sr-only">{{ 'shared.common.close' | translate }}</span>
  </button>
</div>
