<div
  *ngIf="isOpen"
  class="fixed z-5000 inset-0 overflow-hidden"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="modal-backdrop"></div>
  <div class="modal-container" (click)="clickOutside($event)" #modalContainer>
    <div class="modal" [class]="modalClass" [ngClass]="modalSize">
      <div *ngIf="modalTitle || !modalDisableClose" class="modal-header">
        <div class="flex justify-between gap-4 pt-4 items-center mb-4" [ngClass]="{ 'mb-2': modalSize === 'small' }">
          <ng-content select="[modal-header-icon]"></ng-content>
          <h3
            class="text-lg leading-6 font-medium text-gray-900 dm-sans"
            id="modal-title"
            [ngClass]="{ 'sr-only': modalHiddenTitle }"
          >
            {{ modalTitle }}
          </h3>
          <button *ngIf="!modalDisableClose" (click)="close()">
            <svg-icon src="/assets/icons/icon-modal-close.svg"></svg-icon>
          </button>
        </div>
        <div class="relative pb-1">
          <ng-content select="[modal-header]"></ng-content>
        </div>
      </div>
      <div class="modal-body">
        <ng-content select="[modal-body]"></ng-content>
      </div>
    </div>
  </div>
</div>
