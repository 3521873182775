import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.html',
  styleUrls: ['./action-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActionButtonComponent),
      multi: true,
    },
  ],
})
export class ActionButtonComponent {
  @Input() label!: string;
  @Input() disabled?: boolean;
  @Input() waiting?: boolean;
  @Input() progressLabel?: string;
  @Input() type?: 'button' | 'submit' | 'reset' = 'button';
  @Input() facet?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link' = 'primary';
  @Input() size?: 'small' | 'medium' = 'medium';
  @Input() isFullWidth?: boolean = false;
  // TODO: DTP-6208 - remove appearance once margin is removed from button style
  @Input() appearance?: string;
  @Output() action: EventEmitter<void> = new EventEmitter<void>();
}
