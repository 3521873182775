import { Action, createReducer, on } from '@ngrx/store';
import { UserInfoDto } from '@app-generated/models/user-info-dto';
import { setUserInfo } from './user-info.actions';

export type State = UserInfoDto;

export const initialState: State = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  type: 'PERSON',
  userId: 0,
  username: '',
  verificationLevel: {},
};

const userInfoReducer = createReducer(
  initialState,
  on(setUserInfo, (state, action) => action.userInfo),
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return userInfoReducer(state, action);
}
