import { Component } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent extends Toast {
  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  getAlertLevelClass(): string {
    if (this.toastClasses.indexOf('error') > -1) {
      return 'error';
    } else if (this.toastClasses.indexOf('info') > -1) {
      return 'info';
    } else {
      return 'success';
    }
  }
}
