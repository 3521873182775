import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import Decimal from 'decimal.js';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatNumber } from '@angular/common';
import { LanguageService } from '@app/shared/services/language.service';
import { Currencies } from '@app/shared/store/currencies/currencies-facade.service';

@Pipe({
  name: 'appFloor',
})
export class AppFloorPipe implements PipeTransform {
  transform(value$: Observable<number> | number, decimalPlaces: number = 8): Observable<string> {
    if (typeof value$ === 'number') value$ = of(value$);
    return value$.pipe(map((value) => floor(value, decimalPlaces)));
  }
}

@Pipe({
  name: 'appCurrencyFloor',
})
export class AppCurrencyFloorPipe implements PipeTransform, OnDestroy {
  private langToLocale: Record<string, string> = {
    en: 'en-US',
    cs: 'cs-CZ',
  } as const;
  private locale = 'cs-CZ';
  private currenciesRounding: { [key: string]: number } = {};
  private currenciesRoundingSubscription?: Subscription;

  constructor(
    private ref: ChangeDetectorRef,
    private languageService: LanguageService,
    private currenciesFacade: Currencies,
  ) {
    this.languageService.currentLanguage$.subscribe((language: string) => {
      this.locale = this.langToLocale[language];
    });

    this.currenciesRoundingSubscription = this.currenciesFacade.currenciesRounding$.subscribe((currenciesRounding) => {
      this.currenciesRounding = currenciesRounding;
    });
  }

  transformNumber(value: number, currency: string, minPrecision: number = 2): string {
    const decimalPlaces = this.currenciesRounding[currency as keyof typeof this.currenciesRounding] || 2;
    const flooredValue = floor(value, decimalPlaces);
    const digitsInfo = ['.', minPrecision.toString(), '-', decimalPlaces.toString()].join('');

    return formatNumber(Number.parseFloat(flooredValue), this.locale, digitsInfo);
  }

  transform(value$: Observable<number> | number, currency: string, minPrecision: number = 2): Observable<string> {
    if (typeof value$ === 'number') {
      value$ = of(value$);
    }

    return value$.pipe(map((v) => this.transformNumber(v, currency, minPrecision)));
  }

  ngOnDestroy() {
    this.currenciesRoundingSubscription?.unsubscribe();
  }
}

export const floor = (value: number, decimalPlaces: number = 8, thousandSeparator?: string): string => {
  if (value === null || value === undefined || isNaN(value)) {
    return '';
  }
  const flooredValue = new Decimal(value).toFixed(decimalPlaces, Decimal.ROUND_DOWN);
  return thousandSeparator ? addThousandSeparator(flooredValue, thousandSeparator) : flooredValue;
};

const addThousandSeparator = (value: string, thousandSeparator: string): string => {
  const decimalSeparator = '.';
  const parts = value.split(decimalSeparator);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  return parts.join(decimalSeparator);
};
