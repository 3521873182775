export enum PaymentType {
  checkoutVisa = 'CHECKOUT_VISA',
  checkoutMastercard = 'CHECKOUT_MASTERCARD',
  bankTransfer = 'BANK_TRANSFER',
  userBalance = 'USER_BALANCE',
}

export enum TradeDirection {
  buy = 'BUY',
  sell = 'SELL',
}

export enum QuickTradeLimitState {
  ok = 'OK',
  underLimit = 'UNDER_LIMIT',
  aboveLimit = 'ABOVE_LIMIT',
  aboveLimitIncreaseIsPossible = 'ABOVE_LIMIT_INCREASE_IS_POSSIBLE',
}

export enum CryptoDisplayName {
  BTC = 'Bitcoin (BTC)',
  USDT = 'USDT',
  ETH = 'Ethereum (ETH)',
  ADA = 'Cardano (ADA)',
  SOL = 'Solana (SOL)',
  LTC = 'Litecoin (LTC)',
  XRP = 'Ripple (XRP)',
}

export enum CryptoDisplayOnlyName {
  BTC = 'Bitcoin',
  USDT = 'USDT',
  ETH = 'Ethereum',
  ADA = 'Cardano',
  SOL = 'Solana',
  LTC = 'Litecoin',
  XRP = 'Ripple',
}

export enum CurrencyDisplayName {
  CZK = 'crypto.CZK',
  EUR = 'Euro',
  BTC = 'Bitcoin',
  USDT = 'USDT',
  ETH = 'Ethereum',
  ADA = 'Cardano',
  SOL = 'Solana',
  LTC = 'Litecoin',
  XRP = 'Ripple',
  DOGE = 'Dogecoin',
}
