import { Component, Inject, inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { JsonLDService } from '@app-shared/services/jsonldservice.service';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { merge, Subject } from 'rxjs';
import { routes } from '@app/app-routing.module';
import { LayoutsEnum } from '@app/layout/layouts.enum';
import { isPlatformBrowser } from '@angular/common';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly templateLayoutsEnum = LayoutsEnum;

  layout = '';

  private unsubscribe$ = new Subject<void>();

  private cookiebotService?: NgxCookiebotService;

  constructor(private jsonIdService: JsonLDService, public router: Router, @Inject(PLATFORM_ID) platformId: string) {
    if (isPlatformBrowser(platformId)) {
      this.cookiebotService = inject(NgxCookiebotService);
    }

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.addGlobalMicrodata();
      }
      if (event instanceof NavigationStart) {
        //removes structural data on navigation. New structural data should be initiated in routed components constructor
        this.jsonIdService.removeStructuredData();
      }
    });
  }

  getLayout(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // cut localization from url and remove query parameters to match routes url
        const urlToMatch = event.hasOwnProperty('urlAfterRedirects') ? event.urlAfterRedirects : event.url;

        let url =
          urlToMatch.startsWith('/cs') || urlToMatch.startsWith('/en') ? urlToMatch.slice(4) : urlToMatch.slice(1);
        url = url.split('?')[0].split('#')[0];

        this.layout = routes.find((i) => url === i.path && i?.data?.layout)?.data?.layout;

        if (url.includes('withdraw/') || url.includes('deposit/') || url.includes('portfolio/')) {
          // solution above does not like dynamic routes
          this.layout = LayoutsEnum.secured;
        }

        // default layout for homepage and 404 page
        if (!url || !this.layout) this.layout = LayoutsEnum.default;
      }
    });
  }

  ngOnInit(): void {
    this.getLayout();

    if (!this.cookiebotService) {
      // cookiebotService is disabled in SSR
      return;
    }

    this.initCookieBotHandler(this.cookiebotService);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // IMPORTANT PART
  // Reload website if user doesn't "allow all" in the cookie dialog
  // This fixes an issue where Cookiebot somehow disables HttpXhrBackend (DTP-4565)
  // Cookiebot also somehow blocks FE and causes freezing
  // This is not a proper fix and should ideally be fixed by changing the Cookiebot settings
  private initCookieBotHandler(cookiebotService: NgxCookiebotService) {
    cookiebotService.onServiceReady$
      .pipe(
        filter((isReady) => isReady),
        switchMap(() => {
          const initialConsentUTC = cookiebotService.cookiebot.consentUTC;
          return merge(cookiebotService.onAccept$, cookiebotService.onDecline$).pipe(
            takeUntil(this.unsubscribe$),
            map(() => {
              return initialConsentUTC;
            }),
          );
        }),
      )
      .subscribe((initialConsentUTC) => {
        const consent = cookiebotService!.cookiebot?.consent;
        // if the user allowed all, do not reload
        if (consent === undefined || (consent.preferences && consent.statistics && consent.marketing)) {
          return;
        }

        // if the user denied or allowed only partially, reload
        // reload only when preferences have changed
        const consentUTC = cookiebotService.cookiebot.consentUTC;
        if (!initialConsentUTC || consentUTC.getTime() !== initialConsentUTC.getTime()) {
          location.reload();
        }
      });
  }

  private addGlobalMicrodata() {
    this.jsonIdService.insertSchema(this.jsonIdService.orgSchema());
  }
}
