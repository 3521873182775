<div class="header-text" *ngIf="showLabel">{{ 'shared.common.phone-number' | translate }}</div>
<div class="phone-number-input phone-number-container" [ngClass]="{ error: validationMessageType === 'error' }">
  <div class="input-dropdown-container">
    <div class="input-group">
      <button (click)="toggleDropdown()" aria-label="Select country" class="btn-container" #toggleButton>
        <span class="icon-row">
          <img [src]="getFlagUrl(selectedCountry?.code)" alt="Country Flag" class="w-[24px] h-[20px]" />
          <span class="icon-space"></span>
          <img src="/assets/icons/icon-arrow-phone.svg" alt="Icon arrow" class="w-[10px] h-[5px]" />
        </span>
      </button>
      <span class="dialing-code-display">{{ selectedCountry?.dialingCode }}</span>
      <input
        type="text"
        [(ngModel)]="userEnteredNumber"
        (input)="onPhoneNumberChange()"
        (blur)="onInputBlur()"
        placeholder="1234567890"
        class="phone-input"
        appPhoneNumber
      />
    </div>
    <div *ngIf="showDropdown" class="country-dropdown" #dropdown>
      <div class="search-container">
        <input type="text" placeholder="{{ 'search' | translate }}" (input)="onSearch($event)" class="search-input" />
        <img src="/assets/icons/icon-search-phone.svg" alt="Search icon" class="search-icon" />
      </div>
      <div [style.height]="dropdownHeight" class="country-list">
        <div *ngFor="let country of filteredCountries" (click)="selectCountry(country)" class="country-row">
          <div class="country-info">
            <img loading="lazy" [src]="getFlagUrl(country.code)" alt="Country Flag" class="w-[24px] h-[20px]" />
            <span class="dialing-code" [ngClass]="selectedCountry?.code === country.code ? 'selected' : 'normal'">
              {{ country.name }} ({{ country.dialingCode }})
            </span>
          </div>
          <img
            *ngIf="selectedCountry?.code === country.code"
            src="/assets/icons/icon-check.svg"
            alt="Icon check"
            class="w-[14px]"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="validation-message"
  [ngClass]="{
    error: validationMessageType === 'error',
    info: validationMessageType === 'info'
  }"
>
  {{ validationMessage }}
</div>
