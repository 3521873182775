<div class="flex justify-between w-full items-center">
  <ng-container *ngIf="currencyBalancesForSelectedPair$ | async as currencyBalancesForSelectedPair">
    <div class="flex flex-col">
      <ul
        class="flex lg:space-x-4 items-center list-none cursor-pointer h-9 text-[12px] sm:text-[14px] leading-[16px] font-sans"
        (click)="openModal()"
      >
        <li class="hidden md:inline-block font-normal text-cm-grey-400 leading-[20px]">{{ 'balances' | translate }}</li>
        <li class="mr-4">
          <svg-icon class="inline-block text-cm-blue" src="/assets/icons/icon-info.svg"></svg-icon>
        </li>
        <li class="flex lg:space-x-4 hover:underline">
          <ul class="flex flex-col lg:flex-row justify-end lg:space-x-4 list-none">
            <li
              *ngFor="let balance of currencyBalancesForSelectedPair"
              class="flex items-center space-x-1 text-cm-blue-900 tracking-tight"
            >
              <span class="font-semibold">{{ balance.name }}:</span>
              <span class="font-normal">{{ balance.available | appCurrencyFloor: balance.name | async }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </ng-container>

  <app-currency-pair-selector
    [isLoggedIn]="isLoggedIn"
    (changePair)="setCurrencyPair($event)"
  ></app-currency-pair-selector>
</div>
