import { Component, OnInit } from '@angular/core';
import { FooterColumn, FooterLink } from '@app/shared/api/footer.api';
import { LanguageSwitchTypeEnum } from '@app/shared/components/language-switch/language-switch.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GuiParamsDto } from '@app/generated/models/gui-params-dto';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  languageSwitchTypeEnum = LanguageSwitchTypeEnum;

  signUpLink: FooterLink = { text: 'shared.footer.signup', routerLink: '/sign-up', link: null };

  product: FooterColumn = {
    links: [
      { text: 'shared.footer.buy-sell', routerLink: '/quick-trade', link: null },
      { text: 'shared.footer.trade', routerLink: null, link: '/trade' },
      {
        text: 'shared.footer.otc-desk',
        routerLink: null,
        lang: ['cs'],
        link: 'https://coinmate.io/cz/otc-desk/',
        openInNewTab: false,
      },
      {
        text: 'shared.footer.tradingview',
        routerLink: null,
        lang: ['cs', 'en'],
        link: 'https://coinmate.io/cz/tradingview/',
        openInNewTab: false,
      },
      { text: 'shared.footer.support', routerLink: '/support', link: null },
    ],
  };

  coinmate: FooterColumn = {
    links: [
      { text: 'shared.footer.fees', routerLink: '/fees', link: null },
      { text: 'shared.footer.cryptocurrencies', routerLink: '/prices', link: null },
      { text: 'shared.menu.our-story', routerLink: '/our-story', link: null },
      { text: 'shared.footer.affiliate', routerLink: '/affiliate', link: null },
      {
        text: 'shared.footer.career',
        routerLink: null,
        lang: ['cs'],
        link: 'https://www.coinmate.io/cz/kariera',
        openInNewTab: false,
      },
    ],
  };

  resources: FooterColumn = {
    links: [
      { text: 'shared.footer.legal-privacy', routerLink: '/legal', link: null },
      { text: 'shared.footer.whistleblowing', routerLink: '/whistleblowing', link: null },
      { text: 'shared.footer.api-services', routerLink: '/developers', link: null },
      {
        text: 'shared.footer.bug-bounty',
        routerLink: null,
        link: 'https://hackerone.com/coinmate',
        openInNewTab: true,
      },
      { text: 'shared.footer.confirmo', routerLink: null, link: 'https://confirmo.net/', openInNewTab: true },
    ],
  };

  currentVersion$!: Observable<string | undefined>;
  appState = 'operational';

  constructor(private guiParams: GuiParams) {}

  ngOnInit() {
    this.currentVersion$ = this.guiParams.guiParams$.pipe(
      map((guiParams: GuiParamsDto) => (guiParams.versionDisplayed ? guiParams.version : undefined)),
    );

    this.guiParams.loggedIn$.subscribe((logged: boolean) => {
      if (!logged && this.product.links.length === 5) {
        this.product.links.unshift(this.signUpLink);
      }
    });

    this.guiParams.appState$.subscribe((appState: string) => {
      this.appState = appState || 'operational';
    });
  }

  getCurrentYear(): number {
    const date = new Date();
    return date.getFullYear();
  }
}
