import { createAction, props } from '@ngrx/store';
import { AccountDto } from '@app/generated/models/account-dto';
import { AccountValueHistoryDto } from '@app/generated/models/account-value-history-dto';
import { ApiError } from '@app/shared/api/error.api';
import { AccountBalanceMoveDto } from '@app/generated/models/account-balance-move-dto';
import { AccountUpdateReason } from '@app/authenticated/portfolio/ui/portfolio.enum';

//region Reset store
export const resetStore = createAction('[Portfolio] Reset store');
//endregion

//region Account ID
export const setSelectedAccount = createAction(
  '[Portfolio] Set selected account',
  props<{ accountId: AccountDto['id'] }>(),
);
//endregion

//region Accounts
export const loadAccounts = createAction('[Portfolio] Load Accounts');

export const loadAccountsLoading = createAction('[Portfolio] Load Accounts: Loading');

export const loadAccountsSuccess = createAction('[Portfolio] Load Accounts: Success', props<{ data: AccountDto[] }>());

export const loadAccountsFailure = createAction('[Portfolio] Load Accounts: Failure', props<{ error: ApiError }>());
//endregion

//region Create Account
export const createAccount = createAction(
  '[Portfolio] Create Account',
  props<{ meta: { requestId: string }; accountName: AccountDto['name'] }>(),
);
//endregion

//region Rename Account
export const renameAccount = createAction(
  '[Portfolio] Rename Account',
  props<{ meta: { requestId: string }; accountId: number; accountName: AccountDto['name'] }>(),
);

//region Deactivate Account
export const deactivateAccount = createAction(
  '[Portfolio] Deactivate Account',
  props<{ meta: { requestId: string }; accountId: number }>(),
);
//endregion

//region Update Account
export const updateAccountLoading = createAction(
  '[Portfolio] Update Account: Loading',
  props<{ meta: { reason: AccountUpdateReason; requestId: string } }>(),
);

export const updateAccountSuccess = createAction(
  '[Portfolio] Update Account: Success',
  props<{ meta: { reason: AccountUpdateReason; requestId: string }; data: AccountDto }>(),
);

export const updateAccountFailure = createAction(
  '[Portfolio] Update Account: Failure',
  props<{ meta: { reason: AccountUpdateReason; requestId: string }; error: ApiError }>(),
);

export const updateAccountDelete = createAction(
  '[Portfolio] Update Account: Delete',
  props<{ meta: { reason: AccountUpdateReason; requestId: string } }>(),
);
//endregion

//region Update Account Balance
export const updateAccountBalance = createAction(
  '[Portfolio] Update Account Balance',
  props<{ meta: { reason?: AccountUpdateReason; requestId: string }; accountId: number }>(),
);
//endregion

//region Move Account Balance
export const moveBalanceToAccount = createAction(
  '[Portfolio] Move Account Balance',
  props<{ meta: { requestId: string }; accountId: number; balance: AccountBalanceMoveDto }>(),
);
//endregion

//region Account Value History
export const loadAccountValueHistory = createAction(
  '[Portfolio] Load Account Value History',
  props<{ accountId: AccountDto['id'] }>(),
);

export const loadAccountValueHistoryLoading = createAction('[Portfolio] Load Account Value History: Loading');

export const loadAccountValueHistorySuccess = createAction(
  '[Portfolio] Load Account Value History: Success',
  props<{
    data: {
      id: number;
      history: AccountValueHistoryDto[];
    };
  }>(),
);

export const loadAccountValueHistoryFailure = createAction(
  '[Portfolio] Load Account Value History: Failure',
  props<{ error: ApiError }>(),
);

export const loadAccountValueHistoryInvalid = createAction(
  '[Portfolio] Load Account Value History: Failure',
  props<{ error: ApiError }>(),
);
//endregion

//region Account Value History - total
export const loadValueHistoryTotal = createAction('[Portfolio] Load Value History Total');

export const loadValueHistoryTotalSuccess = createAction(
  '[Portfolio] Load Value History Total: Success',
  props<{ data: AccountValueHistoryDto[] }>(),
);

export const loadValueHistoryTotalFailure = createAction(
  '[Portfolio] Load Value History Total: Failure',
  props<{ error: ApiError }>(),
);
//endregion

//region Favorites
export const loadFavorites = createAction('[Favorites] Load Favorites');
export const loadFavoritesSuccess = createAction(
  '[Favorites] Load Favorites Success',
  props<{ favorites: string[] }>(),
);
export const loadFavoritesFailure = createAction('[Favorites] Load Favorites Failure', props<{ error: ApiError }>());

export const addFavorite = createAction('[Favorites] Add Favorite', props<{ currencyName: string }>());
export const removeFavorite = createAction('[Favorites] Remove Favorite', props<{ currencyName: string }>());
//endregion
