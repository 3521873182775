export const CURRENCY_COLOR = Object.freeze({
  CZK: '#FF6347',
  EUR: '#007bFF',
  USDT: '#26A17B',
  BTC: '#f2A900',
  SOL: '#00FFA3',
  XRP: '#006097',
  ETH: '#3C3C3D',
  LTC: '#BFBBBB',
  ADA: '#0033AD',
});

export const CURRENCY_COLOR_HIGHLIGHTED = Object.freeze({
  CZK: '#FF8C69',
  EUR: '#3399ff',
  USDT: '#33CC99',
  BTC: '#FFCC66',
  SOL: '#66FFCC',
  XRP: '#3399CC',
  ETH: '#666666',
  LTC: '#CCCCCC',
  ADA: '#3366FF',
});

// This is used as a fallback in case the currencies info is not loaded into the shared store
export const CURRENCY_ROUNDING: { [key: string]: number } = Object.freeze({
  CZK: 2,
  EUR: 2,
  BTC: 8,
  USDT: 2,
  ETH: 8,
  ADA: 6,
  SOL: 8,
  LTC: 8,
  XRP: 8,
  BCH: 8,
  DASH: 8,
  DAI: 2,
  DOGE: 8,
});
