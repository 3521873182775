import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import '@app/shared/components/tooltip/polyfill-wrapper';
import { TooltipPosition } from '@app/shared/components/tooltip/tooltip.enum';

const isPopoverApiSupported = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    typeof HTMLElement !== 'undefined' &&
    typeof HTMLElement.prototype === 'object' &&
    'popover' in HTMLElement.prototype
  );
};

@Component({
  selector: 'app-tooltip[text]',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnDestroy, AfterViewInit {
  @Input() text!: string;
  @Input() appearance?: string = 'default';
  @Input() displayDot?: boolean = false;
  @Input() tooltipPosition = TooltipPosition.bottom;
  @Input() usePopover? = false;
  @Input() tooltipClass?: string;

  @ViewChild('tooltipTarget') tooltipTarget!: ElementRef<HTMLElement>;
  @ViewChild('tooltipContent') tooltipContent!: ElementRef<HTMLElement>;

  top?: number = undefined;
  left?: number = undefined;

  protected readonly tooltipPositionEnum = TooltipPosition;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.usePopover && isPopoverApiSupported()) {
      this.tooltipTarget.nativeElement.addEventListener('mouseenter', this.showTooltip.bind(this));
      this.tooltipTarget.nativeElement.addEventListener('mouseleave', this.hideTooltip.bind(this));

      // @ts-ignore
      this.tooltipContent.nativeElement.popover = 'manual';

      this.tooltipContent.nativeElement.addEventListener('beforetoggle', this.onBeforeToggle.bind(this));

      this.tooltipContent.nativeElement.addEventListener('toggle', this.onToggle.bind(this));
    }
  }

  ngOnDestroy(): void {
    if (this.usePopover && isPopoverApiSupported()) {
      this.tooltipTarget.nativeElement.removeEventListener('mouseenter', this.showTooltip);
      this.tooltipTarget.nativeElement.removeEventListener('mouseleave', this.hideTooltip);
      this.tooltipContent.nativeElement.removeEventListener('beforetoggle', this.onBeforeToggle);
      this.tooltipContent.nativeElement.removeEventListener('toggle', this.onToggle);
    }
  }

  private showTooltip() {
    // @ts-ignore
    this.tooltipContent.nativeElement.showPopover();
  }

  private hideTooltip() {
    // @ts-ignore
    this.tooltipContent.nativeElement.hidePopover();
  }

  private onBeforeToggle(event: Event) {
    // @ts-ignore
    if (event.newState === 'open') {
      this.tooltipContent.nativeElement.style.visibility = 'hidden';
    }
  }

  private onToggle(event: Event) {
    // @ts-ignore
    if (event.newState === 'open') {
      this.calculatePosition();
      this.tooltipContent.nativeElement.style.visibility = 'visible';
    }
  }

  private calculatePosition() {
    // In combination with Firefox and the `transform: translate()` property in CSS, it may return incorrect target coordination.
    const tooltipTargetRect = this.tooltipTarget.nativeElement.getBoundingClientRect();
    const tooltipContentRect = this.tooltipContent.nativeElement.getBoundingClientRect();

    if (this.tooltipPosition === TooltipPosition.top) {
      this.top = tooltipTargetRect.top - tooltipContentRect.height;
    }

    if (this.tooltipPosition === TooltipPosition.bottom) {
      this.top = tooltipTargetRect.bottom;
    }

    if (this.tooltipPosition === TooltipPosition.top || this.tooltipPosition === TooltipPosition.bottom) {
      this.left = tooltipTargetRect.left + (tooltipTargetRect.width - tooltipContentRect.width) / 2;
    }

    if (this.tooltipPosition === TooltipPosition.left) {
      this.left = tooltipTargetRect.left - tooltipContentRect.width;
    }

    if (this.tooltipPosition === TooltipPosition.right) {
      this.left = tooltipTargetRect.right;
    }

    if (this.tooltipPosition === TooltipPosition.left || this.tooltipPosition === TooltipPosition.right) {
      this.top = tooltipTargetRect.top + (tooltipTargetRect.height - tooltipContentRect.height) / 2;
    }
  }
}
