export enum TimePeriod {
  '24hours' = '24hours',
  '7days' = '7-days',
  '30days' = '30-days',
  '180days' = '180-days',
}

export enum ValidationType {
  minlength = 'minlength',
  maxlength = 'maxlength',
  min = 'min',
  max = 'max',
}

export enum ErrorType {
  accountDuplicateNameException = 'AccountDuplicateNameException',
  maxNumberOfAccountsException = 'MaxNumberOfAccountsException',
  invalidArgumentException = 'InvalidArgumentException',
}

export enum Modal {
  createAccount = 'createAccount',
  renameAccount = 'renameAccount',
  moveBalanceToAccount = 'moveBalanceToAccount',
  deactivateAccount = 'deactivateAccount',
  balances = 'balances',
}

export enum AccountUpdateReason {
  createAccount = 'createAccount',
  renameAccount = 'renameAccount',
  deactivateAccount = 'deactivateAccount',
  moveBalanceToAccount = 'moveBalanceToAccount',
  updateBalance = 'updateBalance',
}

export enum PortfolioUserSettingsKeys {
  timePeriod = 'timePeriod',
}
