/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CurrencyDto } from '../models/currency-dto';
import { CurrencyPairDto } from '../models/currency-pair-dto';
import { CurrencyPairWithStatsDto } from '../models/currency-pair-with-stats-dto';
import { QuickTradeCreateOfferRequestDto } from '../models/quick-trade-create-offer-request-dto';
import { QuickTradeCreateOfferResult } from '../models/quick-trade-create-offer-result';
import { QuickTradeCreateOrderRequestDto } from '../models/quick-trade-create-order-request-dto';
import { QuickTradeLimitDto } from '../models/quick-trade-limit-dto';
import { QuickTradePaymentMethodsDto } from '../models/quick-trade-payment-methods-dto';
import { QuickTradeResultDto } from '../models/quick-trade-result-dto';

@Injectable({
  providedIn: 'root',
})
export class QuickTradeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation executeOrderUsingOfferIdUsingPost
   */
  static readonly ExecuteOrderUsingOfferIdUsingPostPath = '/frontend/user/quicktrade/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeOrderUsingOfferIdUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeOrderUsingOfferIdUsingPost$Response(params: {
    body: QuickTradeCreateOrderRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.ExecuteOrderUsingOfferIdUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeOrderUsingOfferIdUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeOrderUsingOfferIdUsingPost(params: {
    body: QuickTradeCreateOrderRequestDto
  },
  context?: HttpContext

): Observable<QuickTradeResultDto> {

    return this.executeOrderUsingOfferIdUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeResultDto>) => r.body as QuickTradeResultDto)
    );
  }

  /**
   * Path part for operation validateAndCreateOfferUsingPost
   */
  static readonly ValidateAndCreateOfferUsingPostPath = '/frontend/user/quicktrade/order/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateAndCreateOfferUsingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateAndCreateOfferUsingPost$Response(params: {
    body: QuickTradeCreateOfferRequestDto
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeCreateOfferResult>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.ValidateAndCreateOfferUsingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeCreateOfferResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateAndCreateOfferUsingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateAndCreateOfferUsingPost(params: {
    body: QuickTradeCreateOfferRequestDto
  },
  context?: HttpContext

): Observable<QuickTradeCreateOfferResult> {

    return this.validateAndCreateOfferUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeCreateOfferResult>) => r.body as QuickTradeCreateOfferResult)
    );
  }

  /**
   * Path part for operation doOrderUsingPost
   */
  static readonly DoOrderUsingPostPath = '/frontend/user/quicktrade/oder/do';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doOrderUsingPost()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  doOrderUsingPost$Response(params: {
    firstAmount: number;
    firstCurrencyName: string;
    secondAmount: number;
    secondCurrencyName: string;
    price: number;
    orderTradeType: 'BUY' | 'SELL';
    orderOrigin: 'CHECKOUT_VISA' | 'CHECKOUT_MASTERCARD' | 'BANK_TRANSFER' | 'USER_BALANCE';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.DoOrderUsingPostPath, 'post');
    if (params) {
      rb.query('firstAmount', params.firstAmount, {});
      rb.query('firstCurrencyName', params.firstCurrencyName, {});
      rb.query('secondAmount', params.secondAmount, {});
      rb.query('secondCurrencyName', params.secondCurrencyName, {});
      rb.query('price', params.price, {});
      rb.query('orderTradeType', params.orderTradeType, {});
      rb.query('orderOrigin', params.orderOrigin, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `doOrderUsingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  doOrderUsingPost(params: {
    firstAmount: number;
    firstCurrencyName: string;
    secondAmount: number;
    secondCurrencyName: string;
    price: number;
    orderTradeType: 'BUY' | 'SELL';
    orderOrigin: 'CHECKOUT_VISA' | 'CHECKOUT_MASTERCARD' | 'BANK_TRANSFER' | 'USER_BALANCE';
  },
  context?: HttpContext

): Observable<QuickTradeResultDto> {

    return this.doOrderUsingPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeResultDto>) => r.body as QuickTradeResultDto)
    );
  }

  /**
   * Path part for operation getPaymentMethodsUsingGet
   */
  static readonly GetPaymentMethodsUsingGetPath = '/frontend/user/quicktrade/payment-methods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethodsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodsUsingGet$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradePaymentMethodsDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetPaymentMethodsUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradePaymentMethodsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethodsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodsUsingGet(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<QuickTradePaymentMethodsDto> {

    return this.getPaymentMethodsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradePaymentMethodsDto>) => r.body as QuickTradePaymentMethodsDto)
    );
  }

  /**
   * Path part for operation getOrderStateUsingGet
   */
  static readonly GetOrderStateUsingGetPath = '/frontend/user/quicktrade/order/state';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderStateUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderStateUsingGet$Response(params: {
    txId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetOrderStateUsingGetPath, 'get');
    if (params) {
      rb.query('txId', params.txId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderStateUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderStateUsingGet(params: {
    txId: string;
  },
  context?: HttpContext

): Observable<QuickTradeResultDto> {

    return this.getOrderStateUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeResultDto>) => r.body as QuickTradeResultDto)
    );
  }

  /**
   * Path part for operation isUnderLimitUsingGet
   */
  static readonly IsUnderLimitUsingGetPath = '/frontend/user/quicktrade/limits/under';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isUnderLimitUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isUnderLimitUsingGet$Response(params: {
    amount: number;
    currencyName: string;
    orderOrigin: 'CHECKOUT_VISA' | 'CHECKOUT_MASTERCARD' | 'BANK_TRANSFER' | 'USER_BALANCE';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuickTradeLimitDto>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.IsUnderLimitUsingGetPath, 'get');
    if (params) {
      rb.query('amount', params.amount, {});
      rb.query('currencyName', params.currencyName, {});
      rb.query('orderOrigin', params.orderOrigin, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuickTradeLimitDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isUnderLimitUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isUnderLimitUsingGet(params: {
    amount: number;
    currencyName: string;
    orderOrigin: 'CHECKOUT_VISA' | 'CHECKOUT_MASTERCARD' | 'BANK_TRANSFER' | 'USER_BALANCE';
  },
  context?: HttpContext

): Observable<QuickTradeLimitDto> {

    return this.isUnderLimitUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuickTradeLimitDto>) => r.body as QuickTradeLimitDto)
    );
  }

  /**
   * Path part for operation isLimitIncreasePossibleUsingGet
   */
  static readonly IsLimitIncreasePossibleUsingGetPath = '/frontend/user/quicktrade/limits/increase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isLimitIncreasePossibleUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isLimitIncreasePossibleUsingGet$Response(params: {
    eWalletType: 'BTC' | 'OKPAY' | 'EGOPAY' | 'BANK_WIRE' | 'DACCOUNT' | 'DAI' | 'SOFORT' | 'MONEYPOLO' | 'LTC' | 'VOUCHER' | 'BCH' | 'BILDERLINGS' | 'ETH' | 'XRP' | 'DASH' | 'GOPAY' | 'THEPAY' | 'ADA' | 'SOL' | 'USDT' | 'TRX' | 'CHECKOUT' | 'BTC_LIGHTNING';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.IsLimitIncreasePossibleUsingGetPath, 'get');
    if (params) {
      rb.query('eWalletType', params.eWalletType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isLimitIncreasePossibleUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isLimitIncreasePossibleUsingGet(params: {
    eWalletType: 'BTC' | 'OKPAY' | 'EGOPAY' | 'BANK_WIRE' | 'DACCOUNT' | 'DAI' | 'SOFORT' | 'MONEYPOLO' | 'LTC' | 'VOUCHER' | 'BCH' | 'BILDERLINGS' | 'ETH' | 'XRP' | 'DASH' | 'GOPAY' | 'THEPAY' | 'ADA' | 'SOL' | 'USDT' | 'TRX' | 'CHECKOUT' | 'BTC_LIGHTNING';
  },
  context?: HttpContext

): Observable<boolean> {

    return this.isLimitIncreasePossibleUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation isCardPaymentAvailableUsingGet
   */
  static readonly IsCardPaymentAvailableUsingGetPath = '/frontend/user/quicktrade/limits/available/card';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCardPaymentAvailableUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isCardPaymentAvailableUsingGet$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.IsCardPaymentAvailableUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isCardPaymentAvailableUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isCardPaymentAvailableUsingGet(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.isCardPaymentAvailableUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation isBankPaymentAvailableUsingGet
   */
  static readonly IsBankPaymentAvailableUsingGetPath = '/frontend/user/quicktrade/limits/available/bank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isBankPaymentAvailableUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isBankPaymentAvailableUsingGet$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.IsBankPaymentAvailableUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isBankPaymentAvailableUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isBankPaymentAvailableUsingGet(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.isBankPaymentAvailableUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation isAccountPaymentAvailableUsingGet
   */
  static readonly IsAccountPaymentAvailableUsingGetPath = '/frontend/user/quicktrade/limits/available/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isAccountPaymentAvailableUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isAccountPaymentAvailableUsingGet$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.IsAccountPaymentAvailableUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isAccountPaymentAvailableUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isAccountPaymentAvailableUsingGet(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.isAccountPaymentAvailableUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation isAboveLimitUsingGet
   */
  static readonly IsAboveLimitUsingGetPath = '/frontend/user/quicktrade/limits/above';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isAboveLimitUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isAboveLimitUsingGet$Response(params: {
    amount: number;
    currencyName: string;
    orderOrigin: 'CHECKOUT_VISA' | 'CHECKOUT_MASTERCARD' | 'BANK_TRANSFER' | 'USER_BALANCE';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.IsAboveLimitUsingGetPath, 'get');
    if (params) {
      rb.query('amount', params.amount, {});
      rb.query('currencyName', params.currencyName, {});
      rb.query('orderOrigin', params.orderOrigin, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isAboveLimitUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  isAboveLimitUsingGet(params: {
    amount: number;
    currencyName: string;
    orderOrigin: 'CHECKOUT_VISA' | 'CHECKOUT_MASTERCARD' | 'BANK_TRANSFER' | 'USER_BALANCE';
  },
  context?: HttpContext

): Observable<boolean> {

    return this.isAboveLimitUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getDefaultInputUsingGet
   */
  static readonly GetDefaultInputUsingGetPath = '/frontend/user/quicktrade/defaultInput';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultInputUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultInputUsingGet$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetDefaultInputUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultInputUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultInputUsingGet(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<number> {

    return this.getDefaultInputUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getQuickTradeCurrencyPairsUsingGet
   */
  static readonly GetQuickTradeCurrencyPairsUsingGetPath = '/frontend/user/quicktrade/currency-pairs';

  /**
   * Get all available currency pairs for quick trade
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuickTradeCurrencyPairsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuickTradeCurrencyPairsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyPairDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetQuickTradeCurrencyPairsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyPairDto>>;
      })
    );
  }

  /**
   * Get all available currency pairs for quick trade
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuickTradeCurrencyPairsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuickTradeCurrencyPairsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyPairDto>> {

    return this.getQuickTradeCurrencyPairsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyPairDto>>) => r.body as Array<CurrencyPairDto>)
    );
  }

  /**
   * Path part for operation getQuickTradeCurrencyPairsWithStatsUsingGet
   */
  static readonly GetQuickTradeCurrencyPairsWithStatsUsingGetPath = '/frontend/user/quicktrade/currency-pairs/stats';

  /**
   * Get all available currency pairs with statistics
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuickTradeCurrencyPairsWithStatsUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuickTradeCurrencyPairsWithStatsUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyPairWithStatsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetQuickTradeCurrencyPairsWithStatsUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyPairWithStatsDto>>;
      })
    );
  }

  /**
   * Get all available currency pairs with statistics
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuickTradeCurrencyPairsWithStatsUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuickTradeCurrencyPairsWithStatsUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyPairWithStatsDto>> {

    return this.getQuickTradeCurrencyPairsWithStatsUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyPairWithStatsDto>>) => r.body as Array<CurrencyPairWithStatsDto>)
    );
  }

  /**
   * Path part for operation getCurrenciesInSellPairUsingGet
   */
  static readonly GetCurrenciesInSellPairUsingGetPath = '/frontend/user/quicktrade/currencies/sell/pairs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrenciesInSellPairUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCurrenciesInSellPairUsingGet$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetCurrenciesInSellPairUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrenciesInSellPairUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCurrenciesInSellPairUsingGet(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<Array<CurrencyDto>> {

    return this.getCurrenciesInSellPairUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>) => r.body as Array<CurrencyDto>)
    );
  }

  /**
   * Path part for operation getSellCurrenciesUsingGet
   */
  static readonly GetSellCurrenciesUsingGetPath = '/frontend/user/quicktrade/currencies/sell/first';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSellCurrenciesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getSellCurrenciesUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetSellCurrenciesUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSellCurrenciesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getSellCurrenciesUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyDto>> {

    return this.getSellCurrenciesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>) => r.body as Array<CurrencyDto>)
    );
  }

  /**
   * Path part for operation getCurrenciesInBuyPairUsingGet
   */
  static readonly GetCurrenciesInBuyPairUsingGetPath = '/frontend/user/quicktrade/currencies/buy/pairs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrenciesInBuyPairUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCurrenciesInBuyPairUsingGet$Response(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetCurrenciesInBuyPairUsingGetPath, 'get');
    if (params) {
      rb.query('currencyName', params.currencyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrenciesInBuyPairUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCurrenciesInBuyPairUsingGet(params: {
    currencyName: string;
  },
  context?: HttpContext

): Observable<Array<CurrencyDto>> {

    return this.getCurrenciesInBuyPairUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>) => r.body as Array<CurrencyDto>)
    );
  }

  /**
   * Path part for operation getBuyCurrenciesUsingGet
   */
  static readonly GetBuyCurrenciesUsingGetPath = '/frontend/user/quicktrade/currencies/buy/first';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuyCurrenciesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getBuyCurrenciesUsingGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CurrencyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, QuickTradeControllerService.GetBuyCurrenciesUsingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CurrencyDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuyCurrenciesUsingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getBuyCurrenciesUsingGet(params?: {
  },
  context?: HttpContext

): Observable<Array<CurrencyDto>> {

    return this.getBuyCurrenciesUsingGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>) => r.body as Array<CurrencyDto>)
    );
  }

}
